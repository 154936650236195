import { CredentialResponse } from '@react-oauth/google';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import axios, { BASE_URL } from '../api/axios';

type AuthContextType = {
  auth?: any;
  setAuth?: any;
  logout: () => void;
  login: (values: any) => void;
  googleLogin: (res: CredentialResponse) => void;
  setUserDataAndNavigate: (user: any) => void;
};

type AuthProviderComponentType = {
  children: ReactNode;
};

export type AuthStateType = {
  user?: any;
};

const AuthContext = createContext<AuthContextType>({
  auth: null,
  setAuth: null,
  logout: () => { },
  login: () => { },
  googleLogin: () => { },
  setUserDataAndNavigate: () => { },
});
const LOGIN_URL = 'auth/login';
const LOGOUT_URL = 'auth/logout';
const LOGIN_GOOGLE_URL = 'auth/google';

export const AuthProvider: React.FC<AuthProviderComponentType> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/project';
  const [navigateOnce, setNavigateOnce] = useState(false);

  const [auth, setAuth] = useState<AuthStateType | null>(() => {
    const user = localStorage.getItem('user');
    if (user) {
      return {
        user: JSON.parse(user)?.response
      };
    }
    setNavigateOnce(true);
    return null;
  });

  useEffect(() => {
    if (auth && auth.user && navigateOnce) {
      setNavigateOnce(false);
      navigate('/projectt', { replace: true });
    }
  }, [auth]);

  const setUserDataAndNavigate = (user: any) => {
    setNavigateOnce(true);
    localStorage.setItem('user', JSON.stringify(user));
    setAuth({ user: user?.response });
  }

  const loginUserAndNavigate = async (url: string, values: any) => {
    const response = await axios.post(url, JSON.stringify(values), {
      headers: { 'Content-Type': 'application/json' }
    });
    const user = response.data;
    setUserDataAndNavigate(user);
  };

  const login = async (values: any) => {
    await loginUserAndNavigate(LOGIN_URL, values);
  };

  const googleLogin = async (res: any) => {
    const url = `${BASE_URL}/auth/google`;
    window.location.href = url;
  };

  const logout = async () => {
    // await axios.post(LOGOUT_URL);
    setAuth(null);
    localStorage.removeItem('user');
    navigate('/login', { replace: true });
  };

  const value = {
    auth,
    login,
    googleLogin,
    logout,
    setAuth,
    setUserDataAndNavigate,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;

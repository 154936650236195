import React, { useState, useEffect } from 'react';
import { Table, Button, Spin, Input, Tabs } from 'antd';
import { LoadingOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import './view.css';
import type { TableColumnsType } from 'antd';
import { getGHGStandards, getGHGMethodologies } from '../../api/ghg';

const { TabPane } = Tabs;

interface GHGStandard {
  id: number;
  code: string;
  full_form: string;
  description: string;
}

interface GHGMethodology {
  id: number;
  name: string;
  display_name: string;
}

const ghgStandardColumns: TableColumnsType<GHGStandard> = [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Full Form',
    dataIndex: 'full_form',
    key: 'full_form',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  }
];

const ghgMethodologyColumns: TableColumnsType<GHGMethodology> = [
  {
    title: 'Name',
    dataIndex: 'display_name',
    key: 'name',
  }
];

const ViewGHGMetaComponent: React.FC<{ activeTab?: string }> = ({ activeTab }) => {
  const defaultActiveKey = activeTab === 'ghg_methodologies' ? '2' : '1';
  const [loading, setLoading] = useState<boolean>(false);
  const [ghgStandards, setGHGStandards] = useState<GHGStandard[]>([]);
  const [ghgMethodologies, setGHGMethodologies] = useState<GHGMethodology[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const ghgStandardsData = await getGHGStandards();
        setGHGStandards(ghgStandardsData.response);
        const ghgMethodologiesData = await getGHGMethodologies();
        setGHGMethodologies(ghgMethodologiesData.response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const filteredGHGStandards = ghgStandards.filter(standard =>
    standard.code.toLowerCase().includes(searchText.toLowerCase())
  );

  const filteredGHGMethodologies = ghgMethodologies.filter(methodology =>
    methodology.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="table-container">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <Tabs defaultActiveKey={defaultActiveKey}>
        <TabPane tab="GHG Standards" key="1">
          <div className="table-controls">
            <div>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search GHG Standard"
                value={searchText}
                onChange={e => handleSearch(e.target.value)}
                style={{ marginBottom: '16px', width: '50%' }}
              />
            </div>
            <Link to="/ghg_standards/create">
              <Button type="primary" icon={<PlusOutlined />} style={{ marginRight: '8px' }}>Create GHG Standard</Button>
            </Link>
          </div>
          <Table
            columns={ghgStandardColumns}
            dataSource={filteredGHGStandards.map(ghgStandard => ({ ...ghgStandard, key: ghgStandard.id }))}
            onRow={(record: GHGStandard) => ({
              onClick: () => navigate(`/ghg_standards/edit/${record.id}`),
              style: { cursor: 'pointer' }
            })}
          />
        </TabPane>
        <TabPane tab="Methodologies" key="2">
          <div className="table-controls">
            <div>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search GHG Methodology"
                value={searchText}
                onChange={e => handleSearch(e.target.value)}
                style={{ marginBottom: '16px', width: '50%' }}
              />
            </div>
            <Link to="/ghg_methodologies/create">
              <Button type="primary" icon={<PlusOutlined />} style={{ marginRight: '8px' }}>Create GHG Methodology</Button>
            </Link>
          </div>
          <Table
            columns={ghgMethodologyColumns}
            dataSource={filteredGHGMethodologies.map(ghgMethodology => ({ ...ghgMethodology, key: ghgMethodology.id }))}
            onRow={(record: GHGMethodology) => ({
              onClick: () => navigate(`/ghg_methodologies/edit/${record.id}`),
              style: { cursor: 'pointer' }
            })}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ViewGHGMetaComponent;

import axios from '../api/axios';
import { Outlet } from 'react-router-dom';
import useRefreshToken from '../hooks/useRefreshToken';

const BaseLayout: React.FC = () => {
  const refresh = useRefreshToken();

  const responseIntercept = axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      if (
        (error?.response?.status === 401 || error?.response?.status === 403) &&
        !prevRequest?.sent
      ) {
        prevRequest.sent = true;
        await refresh();
        return axios(prevRequest);
      } else if (
        (error?.response?.status === 401 || error?.response?.status === 403) &&
        prevRequest?.sent
      ) {
        window.location = '/login' as any;
      }
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.eject(responseIntercept);

  return (
    <main className="calyx-root">
      <Outlet />
    </main>
  );
};

export default BaseLayout;

import { AxiosResponse } from 'axios';
import axiosInstance, { BASE_URL } from './axios';

export const getSDGCertificates = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('/sdg_certificates');
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching sdg certificate ${error}`);
  }
};

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, AutoComplete, Spin, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { createProjectType, getProjectTypeGroups } from '../../../api/project_type';
import './create.css';
import { Option } from 'antd/es/mentions';

const CreateProjectTypeComponent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [projectGroups, setProjectGroups] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchProjectGroups() {
      setLoading(true);
      try {
        const groups = await getProjectTypeGroups();
        setLoading(false);
        setProjectGroups(groups.response);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching project groups:', error);
      }
    }

    fetchProjectGroups();
  }, []);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await createProjectType(values);
      message.success({
        content: 'Project type created successfully',
        duration: 4,
        style: { textAlign: 'right' },
      });
      navigate('/project_types');
    } catch (error: any) {
      console.error('Error creating project type:', error);
      message.error({
        content: error.message,
        duration: 4,
        style: { textAlign: 'right' },
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className="create-project-type-container">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <div className="form-container">
        <h1>Create Project Type</h1>
        <Form
          name="createProjectTypeForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input the name of the project type!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Project Group"
            name="project_group_id"
          >
            <Select>
            {projectGroups.filter(group => group.name !== 'orphan').map((group) => (
              <Option key={group.id} value={group.id}>{group.display_name}</Option>
            ))}
          </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateProjectTypeComponent;

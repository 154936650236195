import React, { useState } from 'react';
import { Empty, Button, Drawer, Space, message, Tag, Badge, Popconfirm } from 'antd';
import CalyxCKEditor from '../CKEditor';
import { useMutation, useQueryClient } from 'react-query';
import { syncDocumentContentFromCKEditor } from '../../api';
import CalyxReadonlyCKEditor from '../CKEditor/readonly';
import { isProjectLive } from '../../utils';
import {
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
  QuestionCircleOutlined,
  DeleteFilled
} from '@ant-design/icons';


type ContentEditorProps = {
  saveData: (data: any) => void;
  project: any;
  entityId: string;
  queryKey: string;
  id: string;
  data: any;
  sectionType?: string;
  tableDataId?: string;
};

const ContentEditor: React.FC<ContentEditorProps> = ({
  data,
  project,
  id,
  entityId,
  queryKey,
  saveData,
  sectionType,
  tableDataId
}) => {
  const [open, setOpen] = useState(false);
  const client = useQueryClient();
  const showDrawer = () => setOpen(true);
  const {
    mutate: syncContent,
    isLoading,
    isSuccess,
    error
  } = useMutation(syncDocumentContentFromCKEditor, {
    onSuccess: (data) => {
      client.invalidateQueries({
        queryKey: [queryKey]
      });
      setOpen(false);
    },
    onError: (e) => {
      console.log(e);
      setOpen(false);
    }
  });

  const isNotEditable = isProjectLive(project);
  let isShared = false;
  let dataToRender = data?.content || '';
  let tagsList = [] as Array<string>;
  if (data?.shared_content_paragraph) {
    isShared = true;
    dataToRender = data.shared_content_paragraph.content;
    if (data.shared_content_paragraph.country) {
      tagsList.push(data.shared_content_paragraph.country);
    }
    if (data.shared_content_paragraph.project_type_group) {
      tagsList.push(data.shared_content_paragraph.project_type_group);
    }
    if (data.shared_content_paragraph.project_type) {
      tagsList.push(data.shared_content_paragraph.project_type);
    }
    if (data.shared_content_paragraph.ghg_methodology) {
      tagsList.push(data.shared_content_paragraph.ghg_methodology);
    }
    if (data.shared_content_paragraph.ghg_standard) {
      tagsList.push(data.shared_content_paragraph.ghg_standard);
    }
  }

  const commentsCount = data?.ckeditor_content_doc_comments?.length || 0;
  const suggestionsCount = data?.ckeditor_content_doc_suggestions?.length || 0;

  const closeDrawer = () => {
    const documentID = `${process.env.NODE_ENV}_ckeditor_${entityId}_${id}`;
    syncContent({
      documentID,
      ratingBlurbSectionParagraphId: sectionType === 'paragraph' ? id : undefined,
      ratingTableDataId: sectionType === 'table' ? tableDataId : undefined
    });
  };


  return (
    <div className='flex flex-1'>
      <div className='flex-1'>
        {isShared ?
          <div className='mb-1 flex'>
            <Tag color='green'>Shared</Tag>
            {tagsList.map((tag) => (
              <Tag key={tag} color='blue'>{tag}</Tag>
            ))}
          </div>
          : null}
        {commentsCount + suggestionsCount > 0 ? (
          <Badge.Ribbon text={`${commentsCount + suggestionsCount} comments/suggestions`}>
            <CalyxReadonlyCKEditor
              data={dataToRender}
            />
          </Badge.Ribbon>
        ) : (
          <CalyxReadonlyCKEditor
            data={dataToRender}
          />
        )}
      </div>
      {isNotEditable ? null :
        (isShared ? (
          <Popconfirm
              title={`Are you sure you want to edit shared text?`}
              description="Editing this section will override this text for this project only. Any further changes to shared content, will not be reflected here."
              icon={(
                  <QuestionCircleOutlined style={{ color: 'red' }} />
              )}
              onConfirm={showDrawer}
              okText="Yes, edit for this project"
              cancelText="No"
          >
            <Button className='ml-4' type="dashed">
              Edit
            </Button>
          </Popconfirm>
        ) : (
          <Button className='ml-4' type="dashed" onClick={showDrawer}>
            Edit
          </Button>
        ))
        
      }
      <Drawer
        title=''
        width={'75%'}
        closable={true}
        onClose={closeDrawer}
        maskClosable={false}
        destroyOnClose
        open={open}
        extra={
          <Space>
            <Button loading={isLoading} type='primary' onClick={closeDrawer}>Close</Button>
          </Space>
        }
      >
        <CalyxCKEditor
          id={id}
          isShared={isShared}
          entityId={entityId}
          saveData={saveData}
          data={data}
        />
      </Drawer>
    </div>
  );
};

export default ContentEditor;

import { Navigate, Route, Routes } from 'react-router-dom';
import Unauthorized from '../components/unauthorized';

import AppLayout from '../layout';
import BaseLayout from '../layout/base';

import SharedContent from '../screens/shared-content';
import ViewLocationComponent from '../components/LocationMeta/ViewLocation';
import CreateLocationComponent from '../components/LocationMeta/CreateLocationScreen';
import EditLocationComponent from '../components/LocationMeta/EditLocationScreen';
import CreateProjectTypeComponent from '../components/ProjectTypeMeta/ProjectType/create';
import EditProjectTypeComponent from '../components/ProjectTypeMeta/ProjectType/edit';
import ProjectTypeComponent from '../components/ProjectTypeMeta/view';
import CreateProjectGroupComponent from '../components/ProjectTypeMeta/ProjectGroup/create';
import EditProjectGroupComponent from '../components/ProjectTypeMeta/ProjectGroup/edit';
import ViewGHGMetaComponent from '../components/GHGMeta/view';
import CreateGHGStandardComponent from '../components/GHGMeta/GHGStandard/create';
import EditGHGStandardComponent from '../components/GHGMeta/GHGStandard/edit';
import CreateGHGMethodologyComponent from '../components/GHGMeta/GHGMethodology/create';
import EditGHGMethodologyComponent from '../components/GHGMeta/GHGMethodology/edit';
import ViewProponentComponent from '../components/Proponent/view';
import CreateProponentComponent from '../components/Proponent/create';
import EditProponentComponent from '../components/Proponent/edit';
import ViewProjectComponent from '../components/Projects/display/view';
import EditProjectComponent from '../components/Projects/edit/main';
import PropelAuthProvider from '../context/PropelAuthProvider';

const Root: React.FC = () => {
  return (
    <PropelAuthProvider>
      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route element=''>
            <Route path="/" element={<AppLayout />}>
              <Route index element={<ViewProjectComponent />} />
              <Route path="project/:id/cp/:creditingPeriodId" element={<EditProjectComponent activeTab="catcher" />} />
              <Route path="esr/:id/cp/:creditingPeriodId" element={<EditProjectComponent activeTab="esr" />} />
              <Route path="projects" element={<ViewProjectComponent />} />
              <Route path="project/edit/:id/cp/:creditingPeriodId/step/:step" element={<EditProjectComponent activeTab="ratings" />} />
              <Route path="shared-content" element={<SharedContent />} />
              <Route path="locations" element={<ViewLocationComponent />} />
              <Route path="locations/create" element={<CreateLocationComponent />} />
              <Route path="locations/edit/:id" element={<EditLocationComponent />} />
              <Route path="project_types" element={<ProjectTypeComponent />} />
              <Route path="project_types/create" element={<CreateProjectTypeComponent />} />
              <Route path="project_types/edit/:id" element={<EditProjectTypeComponent />} />
              <Route path="project_groups" element={<ProjectTypeComponent activeTab="project_groups" />} />
              <Route path="project_groups/create" element={<CreateProjectGroupComponent />} />
              <Route path="project_groups/edit/:id" element={<EditProjectGroupComponent />} />
              <Route path="ghg_standards" element={<ViewGHGMetaComponent />} />
              <Route path="ghg_standards/create" element={<CreateGHGStandardComponent />} />
              <Route path="ghg_standards/edit/:id" element={<EditGHGStandardComponent />} />
              <Route path="ghg_methodologies" element={<ViewGHGMetaComponent activeTab="ghg_methodologies" />} />
              <Route path="ghg_methodologies/create" element={<CreateGHGMethodologyComponent />} />
              <Route path="ghg_methodologies/edit/:id" element={<EditGHGMethodologyComponent />} />
              <Route path="proponents" element={<ViewProponentComponent />} />
              <Route path="proponents/create" element={<CreateProponentComponent />} />
              <Route path="proponents/edit/:id" element={<EditProponentComponent />} />
              <Route path="*" element={<Navigate to="/projects" replace />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </PropelAuthProvider>
  );
};

export default Root;

import { useAuthInfo } from '@propelauth/react';
import { useDebugValue } from 'react';

const usePropelAuth = () => {
  const authInfo = useAuthInfo();
  
  useDebugValue(authInfo, (authInfo) => (authInfo?.user ? 'Logged In' : 'Logged Out'));
  
  return {
    user: authInfo?.user,
    isLoggedIn: authInfo?.isLoggedIn,
    accessToken: authInfo?.accessToken,
    roles: [authInfo.orgHelper?.getOrgs()?.[0]?.userAssignedRole as string,...authInfo.orgHelper?.getOrgs()?.[0]?.userAssignedAdditionalRoles as string[]]
  };
};

export default usePropelAuth;

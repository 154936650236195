import React, { useState, useEffect } from 'react';
import { Table, Button, Spin, Input } from 'antd';
import { LoadingOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import './view.css';
import type { TableColumnsType } from 'antd';
import { getProponents } from '../../api/proponent';

interface Proponent {
  id: number;
  name: string;
}

const proponentColumns: TableColumnsType<Proponent> = [
  {
    title: 'Name',
    dataIndex: 'display_name',
    key: 'name',
  },
];

const ViewProponentComponent: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [proponents, setProponents] = useState<Proponent[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const proponentsData = await getProponents();
        setProponents(proponentsData.response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const filteredProponents = proponents.filter(proponent =>
    proponent.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="table-container">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <Input
        prefix={<SearchOutlined />}
        placeholder="Search proponent"
        value={searchText}
        onChange={e => handleSearch(e.target.value)}
        style={{ marginBottom: '16px', width: '50%' }}
      />
      <div style={{ marginBottom: '16px' }}>
        <Link to="/proponents/create" style={{ marginRight: '8px' }}>
          <Button type="primary" icon={<PlusOutlined />}>Create a Proponent</Button>
        </Link>
      </div>
      <Table
        columns={proponentColumns}
        dataSource={filteredProponents.map(proponent => ({ ...proponent, key: proponent.id }))}
        onRow={(record: Proponent) => ({
          onClick: () => navigate(`/proponents/edit/${record.id}`),
          style: { cursor: 'pointer' }
        })}
      />
    </div>
  );
};

export default ViewProponentComponent;

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, AutoComplete, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { updateProjectType, getProjectTypeGroups, getProjectTypeById } from '../../../api/project_type';
import './edit.css';

const EditProjectTypeComponent: React.FC = () => {
  const { id } = useParams<{ id }>();
  const [loading, setLoading] = useState(false);
  const [projectGroups, setProjectGroups] = useState<any[]>([]);
  const [searchOptions, setSearchOptions] = useState<any[]>([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchProjectGroups() {
      setLoading(true);
      try {
        const groups = await getProjectTypeGroups();
        const group = await getProjectTypeById(id);
        setLoading(false);
        setProjectGroups(groups.response);
        setSearchOptions(groups.response.map(group => ({ value: group.display_name, key: group.id })));
        form.setFieldsValue({ ...group.response, project_group_id: groups.response.find(groupObj => groupObj.id === group.response.project_group_id)?.display_name });
      } catch (error) {
        setLoading(false);
        console.error('Error fetching project groups:', error);
      }
    }

    fetchProjectGroups();
  }, [id, form]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const project_group_id = projectGroups.find(projectGroup => projectGroup.display_name === values.project_group_id)?.id;
      await updateProjectType(id, { ...values, project_group_id, name: values.display_name });
      message.success({
        content: 'Project type updated successfully',
        duration: 4,
        style: { textAlign: 'right' },
      });
      navigate('/project_types');
    } catch (error: any) {
      console.error('Error updating project type:', error);
      message.error({
        content: error.message,
        duration: 4,
        style: { textAlign: 'right' },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    const filteredOptions = projectGroups.filter(projectGroup =>
      projectGroup.name.toLowerCase().includes(value.toLowerCase())
    ).map(projectGroupObj => ({ value: projectGroupObj.name, key: projectGroupObj.id }));
    setSearchOptions(filteredOptions);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className="edit-project-type-container">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <div className="form-container">
        <h1>Update Project Type</h1>
        <Form
          form={form}
          name="updateProjectTypeForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="display_name"
            rules={[{ required: true, message: 'Please input the name of the project type!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Project Group"
            name="project_group_id"
            rules={[{ required: true, message: 'Please input the project group!' }]}
          >
            <AutoComplete
              options={searchOptions}
              placeholder="Search Project Group"
              onSearch={handleSearch}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EditProjectTypeComponent;

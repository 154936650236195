import { useAuthInfo, useRedirectFunctions } from '@propelauth/react';

const AuthGuard = ({ children }) => {
  const { loading, isLoggedIn } = useAuthInfo();
  const { redirectToLoginPage } = useRedirectFunctions();

  if (loading) {
    return null;
  }

  if (!isLoggedIn) {
    redirectToLoginPage({ userSignupQueryParameters: { "opt_hint": "pw,gl" }, postLoginRedirectUrl: window.location.href });
    return null;
  }

  return <>{children}</>;
};

export default AuthGuard;

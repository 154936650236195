import React, { useState } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { createProjectTypeGroup } from '../../../api/project_type';
import './create.css';

const CreateProjectGroupComponent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await createProjectTypeGroup(values);
      message.success({
        content: 'Project group created successfully',
        duration: 4,
        style: { textAlign: 'right' },
      });
      navigate('/project_groups');
    } catch (error: any) {
      console.error('Error creating project group:', error);
      message.error({
        content: error.message,
        duration: 4,
        style: { textAlign: 'right' },
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className="create-project-group-container">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <div className="form-container">
        <h1>Create Project Group</h1>
        <Form
          name="createProjectGroupForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input the name of the project group!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateProjectGroupComponent;
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Select, AutoComplete, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { createLocation, getLocationTypes, getLocations } from '../../../api/location';
import './createlocation.css';

const { Option } = Select;

const CreateLocationComponent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [locationTypes, setLocationTypes] = useState<string[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [showParentIdField, setShowParentIdField] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const types = await getLocationTypes();
        setLocationTypes(types.response);
        const locations = await getLocations();
        setLoading(false);
        setLocations(locations.response);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);

  const handleLocationTypeChange = (locationType) => {
    form.setFieldValue('type', locationType);
    if (locationType === 'region') {
      setShowParentIdField(false);
    }
    setShowParentIdField(locationType);
  };

  const getLocationParents = (type) => {
    return locations
      .filter((location) => location.type === type)
      .map((parent) => (
        <Option key={parent.id} value={parent.id}>
          {parent.display_name}
        </Option>
      ));
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const parent_id = values.parent_id || null;
      await createLocation({ ...values, parent_id });
      message.success({
        content: 'Location created successfully',
        duration: 4,
        style: { textAlign: 'right' },
      });
      navigate('/locations');
    } catch (error: any) {
      console.error('Error creating location:', error);
      message.error({
        content: error.message,
        duration: 4,
        style: { textAlign: 'right' },
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className="create-location-container">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <div className="form-container">
        <h1>Create Location</h1>
        <Form
          name="createLocationForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please input the name of the location!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Alias"
            name="alias"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: 'Please select the type of location!' }]}
          >
            <Select onChange={handleLocationTypeChange}>
              {locationTypes.map(type => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {form.getFieldValue('type') === 'state' &&
            <Form.Item label="Country" name="parent_id">
              <Select placeholder="Select Country" showSearch
                filterOption={(inputValue, option) =>
                  option?.children?.toString().toLowerCase().includes(inputValue.toLowerCase()) ? true : false
                }>
                {getLocationParents('country')}
              </Select>
            </Form.Item>
          }

          {form.getFieldValue('type') === 'country' &&
            <Form.Item label="Region" name="parent_id">
              <Select placeholder="Select Region" showSearch
                filterOption={(inputValue, option) =>
                  option?.children?.toString().toLowerCase().includes(inputValue.toLowerCase()) ? true : false
                }>
                {getLocationParents('region')}
              </Select>
            </Form.Item>
          }
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateLocationComponent;

import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Table, Button, Spin, Input } from 'antd';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import './viewlocation.css';
import type { TableColumnsType } from 'antd';
import { getLocations } from '../../../api/location';

const { Column } = Table;

interface Location {
  id: number;
  type: string;
  name: string;
  display_name: string;
  alias: string | null;
  parent_id: number | null;
  archived: boolean;
  archived_at: string | null;
  created_at: string;
  updated_at: string;
  parent?: Location | null;
  children?: Location[];
}

const columns: TableColumnsType<Location> = [
  {
    title: 'Name',
    dataIndex: 'display_name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Alias',
    dataIndex: 'alias',
    key: 'alias',
  },
];

const ViewLocationComponent: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [filteredLocations, setFilteredLocations] = useState<Location[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        setLoading(true);
        const data = await getLocations();
        const nestedData = restructureLocations(data.response);
        setLoading(false);
        setLocations(nestedData);
        setFilteredLocations(nestedData);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, []);

  const restructureLocations = (data: Location[]): Location[] => {
    const restructuredData: Location[] = [];

    const processLocations = (locations: Location[]) => {
      locations.forEach(location => {
        const children = data.filter(child => child.parent_id === location.id);
        if (!isEmpty(children)) {
          location.children = children;
        }
        processLocations(children);
      });
    };

    const topLevelLocations = data.filter(location => !location.parent_id);
    processLocations(topLevelLocations);

    return topLevelLocations;
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    const filtered = filterLocations(locations, value.toLowerCase());
    setFilteredLocations(filtered);
  };

  const filterLocations = (locations: Location[], searchText: string): Location[] => {
    return locations.reduce((filtered: Location[], location: Location) => {
      const children = filterLocations(location.children || [], searchText);
      if (location.name.toLowerCase().includes(searchText) || children.length > 0) {
        filtered.push({ ...location, children });
      }
      return filtered;
    }, []);
  };


  return (
    <div className="table-container">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <div className="table-controls">
        <div>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search Location"
            value={searchText}
            onChange={e => handleSearch(e.target.value)}
            style={{ marginBottom: '16px', width: '50%' }}
          />
        </div>
        <Link to="/locations/create">
          <Button type="primary">Create a Location</Button>
        </Link>
      </div>
      <Table
        dataSource={filteredLocations.map(location => ({ ...location, key: location.id }))}
        rowClassName="clickable-row"
        onRow={(location: Location) => ({
          onClick: () => navigate(`/locations/edit/${location.id}`),
        })}
        rowKey="id"
      >
        {columns.map(column => (
          <Column
            key={column.key}
            title={column.title}
            dataIndex={column['dataIndex']}
          />
        ))}
      </Table>
    </div>
  );
};

export default ViewLocationComponent;


import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import {
  Typography,
  Divider,
  Select,
  Button,
  Input,
  Popconfirm,
  Card,
  DatePicker,
  Tabs,
  Spin,
  message,
  Modal
} from 'antd';
import SimpleEditor from './editor/texteditor/texteditor';
import {
  DeleteOutlined,
  UploadOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  LoadingOutlined,
  InboxOutlined
} from '@ant-design/icons';
import {
  createESR,
  createESRSection,
  createProjectRisk,
  createScreeningUpdate,
  deleteESR,
  deleteESRImage,
  deleteESRSection,
  deleteProjectRisk,
  deleteProjectRiskImage,
  deleteScreeningUpdate,
  getFieldMeta,
  getProjectRisks,
  updateESRSection,
  updateProjectRisk,
  updateSafeGuardArea,
  updateScreeningUpdate,
  uploadESRImage,
  uploadProjectRiskImage
} from '../../api/esr';
import { useParams } from 'react-router';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { find } from 'lodash';
import { getProjectById } from '../../api/project';
import { debounce, includes, groupBy, isEmpty } from 'lodash';
import Dragger from 'antd/es/upload/Dragger';

const { Text } = Typography;
const { Option } = Select;

enum ProjectStatusEnum {
  DRAFT = 'draft',
  STAGING = 'staging',
  PRODUCTION = 'production'
}

const SafeguardSection = () => {
  const { id, creditingPeriodId } = useParams<{ id: any; creditingPeriodId: any }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [creditingPeriod, setCreditingPeriod] = useState<any>({});
  const [esrData, setESRData] = useState<any>({});
  const [fieldMeta, setFieldMeta] = useState<any>({});
  const [removeESRModalOpen, setRemoveESRModalOpen] = useState<any>(false);
  const [workflowState, setWorkflowState] = useState<ProjectStatusEnum>(ProjectStatusEnum.STAGING);

  const client = useQueryClient();

  const fetchESRData = async () => {
    try {
      const esr = await getProjectRisks(creditingPeriod.id);
      setESRData(esr.data);
      setLoading(false); //Do not remove this
    } catch (err) {
      console.log('Error fetching latest esr data', err);
      message.error('Unable to fetch the esr data. Please try after some time');
      setLoading(false);
    }
  };

  const fetchProjectData = async () => {
    try {
      setLoading(true);
      const projectData = await getProjectById(id, Number(creditingPeriodId));
      const metaResponse = await getFieldMeta();
      const currentCreditingPeriod = projectData.response.project_crediting_periods.find(
        (cp) => cp.crediting_period_id === Number(creditingPeriodId)
      );
      setWorkflowState(currentCreditingPeriod?.project_workflow?.state);
      setFieldMeta(metaResponse.data);
      setLoading(false);
      setCreditingPeriod(currentCreditingPeriod);
    } catch (err) {
      console.log('Error fetching project data', err);
      message.error('Unable to fetch the project data. Please try after some time');
      setLoading(false);
    }
  };

  useQuery<any>(['projectData', id, creditingPeriodId], () => fetchProjectData());

  const { data, isLoading: loadingEsr } = useQuery<any>(
    ['esrData', id, creditingPeriodId],
    () => fetchESRData(),
    {
      enabled: !isEmpty(creditingPeriod)
    }
  );

  const addRiskMutation = async ({ area }) => {
    setLoading(true);
    const newRisk = {
      esr_safeguard_area_id: area.id,
      descriptive_name: ''
    };
    return createProjectRisk(newRisk);
  };

  const addESRMutation = async () => {
    setLoading(true);
    return createESR(creditingPeriod?.id);
  };

  const removeESRMutation = async ({ id }) => {
    setLoading(true);
    return deleteESR(id);
  };

  const addESRSectionMutation = async () => {
    setLoading(true);
    const newSection = {
      esr_id: esrData.id,
      sectionType: 'image',
      title: 'Image'
    };
    return createESRSection(newSection);
  };

  const removeESRSectionMutation = async ({ id }) => {
    setLoading(true);
    return deleteESRSection(id);
  };

  const addImageMutation = async ({ id, file }) => {
    setLoading(true);
    await uploadESRImage(id, file);
  };

  const removeImageMutation = async ({ id }) => {
    setLoading(true);
    await deleteESRImage(id);
  };

  const addProjectRiskImageMutation = async ({ id, file }) => {
    setLoading(true);
    await uploadProjectRiskImage(id, file);
  };

  const removeProjectRiskImageMutation = async ({ id }) => {
    setLoading(true);
    await deleteProjectRiskImage(id);
  };

  const {
    mutate: addESR,
    isLoading: isAddingESR,
    error: addEsrError
  } = useMutation(addESRMutation, {
    onSuccess: () => {
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      setLoading(false);
      message.error('Failed to create the esr. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const {
    mutate: removeESR,
    isLoading: isremovingESR,
    error: removeEsrError
  } = useMutation(removeESRMutation, {
    onSuccess: () => {
      setLoading(false);
      setRemoveESRModalOpen(false);
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      setLoading(false);
      setRemoveESRModalOpen(false);
      message.error('Failed to remove the esr. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const {
    mutate: addImage,
    isLoading: isAddingImage,
    error: addImageError
  } = useMutation(addImageMutation, {
    onSuccess: () => {
      setLoading(false);
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      setLoading(false);
      message.error('Failed to upload the image. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const {
    mutate: removeImage,
    isLoading: isremovingImage,
    error: removeImageError
  } = useMutation(removeImageMutation, {
    onSuccess: () => {
      setLoading(false);
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      setLoading(false);
      message.error('Failed to remove the image. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const {
    mutate: addProjectRiskImage,
    isLoading: isAddingProjectRiskImage,
    error: addProjectRiskImageError
  } = useMutation(addProjectRiskImageMutation, {
    onSuccess: () => {
      setLoading(false);
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      setLoading(false);
      message.error('Failed to upload the project risk image. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const {
    mutate: removeProjectRiskImage,
    isLoading: isRemovingProjectRiskImage,
    error: removeProjectRiskImageError
  } = useMutation(removeProjectRiskImageMutation, {
    onSuccess: () => {
      setLoading(false);
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      setLoading(false);
      message.error('Failed to remove the project risk image. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const {
    mutate: addESRSection,
    isLoading: isAddingESRSection,
    error: addRiskSectionError
  } = useMutation(addESRSectionMutation, {
    onSuccess: () => {
      setLoading(false);
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      setLoading(false);
      message.error('Failed to add the esr section. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const {
    mutate: removeESRSection,
    isLoading: isremovingSection,
    error: removeSectionError
  } = useMutation(removeESRSectionMutation, {
    onSuccess: () => {
      setLoading(false);
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      setLoading(false);
      message.error('Failed to remove the esr section. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const {
    mutate: addRisk,
    isLoading: isAddingRisk,
    error: addRiskError
  } = useMutation(addRiskMutation, {
    onSuccess: () => {
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      message.error('Failed to add the risk. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const removeRiskMutation = async ({ riskId }) => {
    setLoading(true);
    await deleteProjectRisk(riskId);
  };

  const {
    mutate: removeRisk,
    isLoading: isRemovingRisk,
    error: removeRiskError
  } = useMutation(removeRiskMutation, {
    onSuccess: () => {
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      message.error('Failed to remove the risk. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const updateRiskFieldMutation = async ({ id, field, value }) => {
    await updateProjectRisk(id, { [field]: value });
  };

  const {
    mutate: updateRiskField,
    isLoading: isUpdatingRiskField,
    error: updateRiskFieldError
  } = useMutation(updateRiskFieldMutation, {
    onSuccess: () => {
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      message.error('Failed to update the field. Please try again.');
    }
  });

  const handleUpdateRiskField = async ({ area, riskIndex, field, value }) => {
    const existingRisk = find(area.risks, (_, index) => index === riskIndex);
    existingRisk[field] = value;
    if (
      includes(
        ['descriptive_name', 'mitigation_description', 'risk_related_to_lack_of_info_reason'],
        field
      )
    ) {
      debouncedUpdateProjectRisk(existingRisk.id, field, value);
    } else {
      updateRiskField({ id: existingRisk.id, field, value });
    }
  };

  const debouncedUpdateProjectRisk = useMemo(
    () =>
      debounce(async (id, field, value) => {
        try {
          await updateRiskField({ id, field, value });
        } catch {
          message.error('Failed to update the risk field. Please try again.');
        }
      }, 1000),
    []
  );

  const updateRiskDescription = async (area, riskIndex, field) => {
    return (content) => {
      handleUpdateRiskField({ area, riskIndex, field, value: content });
    };
  };

  const handleSafeGuardFieldChangeMutation = async ({ area, field, value }) => {
    area[field] = value;
    await updateSafeGuardArea(area.id, { [field]: value });
  };

  const {
    mutate: handleSafeGuardFieldChange,
    isLoading: isUpdatingSafeGuardField,
    error: handleSafeGuardFieldChangeError
  } = useMutation(handleSafeGuardFieldChangeMutation, {
    onSuccess: () => {
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      message.error('Failed to update the field. Please try again.');
    }
  });

  const addScreeningUpdateMutation = async () => {
    setLoading(true);
    const newScreeningUpdate = {
      update_date: dayjs(),
      esr_id: esrData.id,
      description: ''
    };
    await createScreeningUpdate(newScreeningUpdate);
  };

  const {
    mutate: addScreeningUpdate,
    isLoading: isAddingScreeningUpdate,
    error: addScreeningUpdateError
  } = useMutation(addScreeningUpdateMutation, {
    onSuccess: () => {
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      message.error('Failed to add the screening update. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const removeScreeningUpdateMutation = async (index) => {
    setLoading(true);
    const screeningId = esrData.screening_updates[index].id;
    await deleteScreeningUpdate(screeningId);
  };

  const {
    mutate: removeScreeningUpdate,
    isLoading: isRemovingScreeningUpdate,
    error: removeScreeningUpdateError
  } = useMutation(removeScreeningUpdateMutation, {
    onSuccess: () => {
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      message.error('Failed to remove the screening update. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const debouncedUpdateScreening = useCallback(
    debounce(async (id, field, value) => {
      try {
        await updateScreeningUpdateField({ id, field, value });
      } catch (err) {
        message.error('Failed to update the screening update field. Please try again.');
      }
    }, 1000),
    []
  );

  const updateScreeningUpdateFieldMutation = async ({ id, field, value }) => {
    await updateScreeningUpdate(id, { [field]: value });
  };

  const {
    mutate: updateScreeningUpdateField,
    isLoading: isUpdatingScreeningUpdateField,
    error: updateScreeningUpdateFieldError
  } = useMutation(updateScreeningUpdateFieldMutation, {
    onSuccess: () => {
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      message.error('Failed to update the screening update field. Please try again.');
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const handleScreeningUpdateChange = async ({ index, field, value }) => {
    const screeningUpdate = esrData.screening_updates[index];
    if (field === 'update_date') {
      value = value ? dayjs(value).utc().format() : dayjs().utc().format();
    }
    screeningUpdate[field] = value;
    if (field === 'description') {
      await debouncedUpdateScreening(screeningUpdate.id, field, value);
    } else {
      await updateScreeningUpdateField({ id: screeningUpdate.id, field, value });
    }
  };

  const updateSectionMutation = async ({ id, value }) => {
    await updateESRSection(id, { description: value });
  };

  const {
    mutate: updateSection,
    isLoading: isUpdatingSection,
    error: updateSectionError
  } = useMutation(updateSectionMutation, {
    onSuccess: () => {
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    },
    onError: () => {
      message.error(`Failed to update the section. Please try again.`);
      client.invalidateQueries(['esrData', id, creditingPeriodId]);
    }
  });

  const updateSectionField = async ({ sectionType }) => {
    return (content) => {
      setESRData((prevEsr) => {
        const section = find(prevEsr?.esr_sections, { section_type: sectionType });
        section.description = content;
        updateSection({ id: section.id, value: content });
        return prevEsr;
      });
    };
  };

  const initialScreening = esrData?.screening_updates?.[0] || {};
  const screeningUpdates = esrData?.screening_updates?.slice(1) || [];
  const imageSection = find(esrData?.esr_sections, { section_type: 'image' });

  let safeguardAreas: {
    [category: string]: any[];
  } = {};
  if (esrData) {
    safeguardAreas = groupBy(
      esrData.esr_safeguard_areas,
      (area) => area.safeguard_area?.area_covered_category?.display_name || 'Uncategorized'
    );
  }

  const ImageComponentSection = ({
    imageSection,
    addSection,
    removeSection,
    addImage,
    removeImage,
    descriptionChange
  }) => {
    return (
      <div className="mt-2 mb-6">
        {!isEmpty(imageSection) ? (
          <div className="py-4 px-10 bg-gray-100 border-2 border-solid border-gray-300">
            {imageSection.url ? (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                <div style={{ width: '85%' }}>
                  <img src={imageSection.url} alt="Uploaded" style={{ width: '100%' }} />
                </div>
                <div style={{ width: '15%' }}>
                  <Popconfirm
                    title="Are you sure you want to remove this image?"
                    onConfirm={removeImage}
                    okText="Yes"
                    cancelText="No">
                    <Button
                      type="link"
                      icon={<DeleteOutlined />}
                      disabled={workflowState === ProjectStatusEnum.PRODUCTION ? true : false}
                      danger>
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            ) : (
              <Dragger
                name="image"
                multiple={false}
                maxCount={1}
                showUploadList={false}
                customRequest={async (options) => {
                  addImage(options.file);
                }}
                style={{ marginBottom: '16px', marginTop: '16px', maxWidth: '91%' }}
                disabled={workflowState === ProjectStatusEnum.PRODUCTION ? true : false}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </Dragger>
            )}

            <div style={{ display: 'flex', alignItems: 'center', marginTop: '18px' }}>
              <div style={{ width: '85%' }}>
                <SimpleEditor
                  initialData={imageSection.description}
                  onChange={(content) => {
                    descriptionChange(content);
                  }}
                  editable={workflowState === ProjectStatusEnum.STAGING ? true : false}
                />
              </div>
              <div style={{ width: '15%' }}>
                <Popconfirm
                  title="Are you sure you want to remove the image section?"
                  onConfirm={removeSection}
                  okText="Yes"
                  cancelText="No">
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    disabled={workflowState === ProjectStatusEnum.PRODUCTION ? true : false}
                    danger>
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            </div>
          </div>
        ) : (
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={addSection}
            disabled={workflowState === ProjectStatusEnum.PRODUCTION ? true : false}>
            Add Image
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className="w-full h-full ml-7 mt-2 mr-16">
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />}
        fullscreen
      />
      {!isEmpty(esrData) ? (
        <>
          <Modal
            title="Remove ESR"
            open={removeESRModalOpen}
            onOk={() => removeESR({ id: esrData.id })}
            onCancel={() => setRemoveESRModalOpen(false)}
            style={{ top: '20%' }}>
            <div>
              <p>This action will delete the esr content of this project. Please confirm.</p>
            </div>
          </Modal>
          <div className="mb-4 mr-16">
            <div className="flex justify-between items-center">
              <Text className="text-xl font-semibold mb-4">Summary</Text>
              <Button
                type="default"
                danger
                icon={<MinusCircleOutlined />}
                onClick={() => setRemoveESRModalOpen(true)}
                disabled={workflowState === ProjectStatusEnum.PRODUCTION ? true : false}>
                Remove ESR
              </Button>
            </div>
            <Divider className="my-4" />
            <p className="text-lg font-semibold mb-4">Intro</p>
            <SimpleEditor
              initialData={
                find(esrData?.esr_sections, { section_type: 'main_summary' })?.description
              }
              onChange={async (content) => {
                const updatefn = await updateSectionField({ sectionType: 'main_summary' });
                updatefn(content);
              }}
              editable={workflowState === ProjectStatusEnum.STAGING ? true : false}
            />
            <p className="text-lg font-semibold mb-4">Screening Timeline</p>
            <div className="mb-4 border-2 p-5 border-solid border-gray-300">
              <p className="text-lg font-semibold mb-4">Date of Initial Screening</p>
              {initialScreening && (
                <DatePicker
                  value={
                    initialScreening.update_date ? dayjs(initialScreening.update_date) : dayjs()
                  }
                  picker="month"
                  onChange={(date) =>
                    handleScreeningUpdateChange({
                      index: 0,
                      field: 'update_date',
                      value: date
                    })
                  }
                  style={{ width: '15%', marginBottom: '26px' }}
                  disabled={workflowState === ProjectStatusEnum.PRODUCTION ? true : false}
                />
              )}

              <p className="text-lg font-semibold mb-4">Screening Updates</p>
              {screeningUpdates.map((update, index) => (
                <div key={update.id} className="mb-2 flex items-center">
                  <DatePicker
                    defaultValue={update.update_date ? dayjs(update.update_date) : dayjs()}
                    picker="month"
                    onChange={(date) =>
                      handleScreeningUpdateChange({
                        index: index + 1,
                        field: 'update_date',
                        value: date
                      })
                    }
                    style={{ marginRight: '16px' }}
                    disabled={workflowState === ProjectStatusEnum.PRODUCTION ? true : false}
                  />
                  <Input.TextArea
                    placeholder="Brief explanation of what changed"
                    defaultValue={update.description || ''}
                    onChange={(e) =>
                      handleScreeningUpdateChange({
                        index: index + 1,
                        field: 'description',
                        value: e.target.value
                      })
                    }
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    style={{ flex: 1 }}
                    disabled={workflowState === ProjectStatusEnum.PRODUCTION ? true : false}
                  />
                  <Button
                    type="link"
                    onClick={() => removeScreeningUpdate(index + 1)}
                    icon={<MinusCircleOutlined />}
                    style={{ marginLeft: '8px', color: 'green' }}
                    disabled={workflowState === ProjectStatusEnum.PRODUCTION ? true : false}
                  />
                </div>
              ))}

              <Button
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => addScreeningUpdate()}
                style={{ marginTop: '8px' }}
                disabled={workflowState === ProjectStatusEnum.PRODUCTION ? true : false}>
                Add Screening Update
              </Button>
            </div>
          </div>

          <div className="mb-4 mr-16">
            <Divider className="my-2" />
            <Tabs
              items={[
                {
                  key: '1',
                  label: `Detailed Risk Findings`,
                  children: (
                    <>
                      <p className="text-lg font-semibold mb-4">Intro</p>
                      <SimpleEditor
                        initialData={
                          find(esrData?.esr_sections, { section_type: 'detailed_risk_summary' })
                            ?.description
                        }
                        onChange={async (content) => {
                          const updatefn = await updateSectionField({
                            sectionType: 'detailed_risk_summary'
                          });
                          updatefn(content);
                        }}
                        editable={workflowState === ProjectStatusEnum.STAGING ? true : false}
                      />
                      <p className="text-lg font-semibold">Image (Optional)</p>
                      <ImageComponentSection
                        imageSection={
                          imageSection
                            ? {
                                url: imageSection.image_url,
                                description: imageSection.description
                              }
                            : {}
                        }
                        addSection={() => addESRSection()}
                        removeSection={() => removeESRSection({ id: imageSection.id })}
                        addImage={(file) => addImage({ id: imageSection.id, file })}
                        removeImage={() => removeImage({ id: imageSection.id })}
                        descriptionChange={async (content) => {
                          const updatefn = await updateSectionField({
                            sectionType: 'image'
                          });
                          updatefn(content);
                        }}
                      />

                      {Object.entries(safeguardAreas)?.map(([category, areas]) => (
                        <div>
                          <p className="text-lg font-semibold mb-4">{category} risks</p>
                          <Divider className="my-4" />
                          {areas?.map((area, areaIndex) => (
                            <Card key={area.id} className="mb-4">
                              <p className="text-lg font-semibold mb-4">
                                {areaIndex + 1}. {area.safeguard_area.display_name}
                              </p>
                              <div className="flex items-center mb-5">
                                <Text className="font-bold text-gray-600">Risk Status</Text>
                                <Select
                                  placeholder="Select Risk Status"
                                  onChange={(value) =>
                                    handleSafeGuardFieldChange({
                                      area,
                                      field: 'risks_identified',
                                      value
                                    })
                                  }
                                  value={area.risks_identified}
                                  style={{ marginLeft: '12px', width: '20%', borderRadius: '4px' }}
                                  disabled={
                                    workflowState === ProjectStatusEnum.PRODUCTION ? true : false
                                  }>
                                  <Option value={false}>No Risks Identified</Option>
                                  <Option value={true}>Risks Identified</Option>
                                </Select>
                              </div>

                              {area?.risks_identified ? (
                                <div>
                                  {area.risks?.map((risk, riskIndex) => (
                                    <div
                                      key={risk.id}
                                      className="mb-10 py-4 px-10 bg-gray-100 border-2 border-solid border-gray-300">
                                      <div className="flex justify-end mr-10">
                                        <Popconfirm
                                          title="Are you sure you want to remove this risk?"
                                          onConfirm={() => removeRisk({ riskId: risk.id })}
                                          okText="Yes"
                                          cancelText="No">
                                          <Button
                                            type="link"
                                            icon={<DeleteOutlined />}
                                            disabled={
                                              workflowState === ProjectStatusEnum.PRODUCTION
                                                ? true
                                                : false
                                            }
                                            danger>
                                            Remove
                                          </Button>
                                        </Popconfirm>
                                      </div>
                                      <div className="flex flex-wrap -mx-2 mb-2">
                                        <div className="w-full md:w-full px-2 mb-2">
                                          <Text className="font-semibold text-gray-600">
                                            Descriptive Name for Risk
                                          </Text>
                                          <Input.TextArea
                                            placeholder="Enter risk name"
                                            defaultValue={risk.descriptive_name}
                                            onChange={(e) =>
                                              handleUpdateRiskField({
                                                area,
                                                riskIndex,
                                                field: 'descriptive_name',
                                                value: e.target.value
                                              })
                                            }
                                            autoSize={{ minRows: 2, maxRows: 4 }}
                                            className="mt-1 w-full"
                                            disabled={
                                              workflowState === ProjectStatusEnum.PRODUCTION
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="flex flex-wrap -mx-2 mb-2">
                                        <div className="w-full md:w-1/2 px-2 mb-4">
                                          <Text className="font-semibold text-gray-600">
                                            Risk Level
                                          </Text>
                                          <Select
                                            placeholder="Select Risk Level"
                                            value={risk.risk_level}
                                            onChange={(value) =>
                                              handleUpdateRiskField({
                                                area,
                                                riskIndex,
                                                field: 'risk_level',
                                                value
                                              })
                                            }
                                            className="mt-1 w-full"
                                            disabled={
                                              workflowState === ProjectStatusEnum.PRODUCTION
                                                ? true
                                                : false
                                            }>
                                            {fieldMeta.riskLevel.map((level) => (
                                              <Option key={level.name} value={level.name}>
                                                {level.display_name}
                                              </Option>
                                            ))}
                                          </Select>
                                        </div>
                                        <div className="w-full md:w-1/2 px-2 mb-4">
                                          <Text className="font-semibold text-gray-600">
                                            Risk Related To
                                          </Text>
                                          <Select
                                            placeholder="Select Related To"
                                            value={risk.risk_related_to}
                                            onChange={(value) =>
                                              handleUpdateRiskField({
                                                area,
                                                riskIndex,
                                                field: 'risk_related_to',
                                                value
                                              })
                                            }
                                            className="mt-1 w-full"
                                            disabled={
                                              workflowState === ProjectStatusEnum.PRODUCTION
                                                ? true
                                                : false
                                            }>
                                            {fieldMeta.riskRelatedTo.map((relatedTo) => (
                                              <Option key={relatedTo.name} value={relatedTo.name}>
                                                {relatedTo.display_name}
                                              </Option>
                                            ))}
                                          </Select>
                                        </div>
                                      </div>

                                      <div className="flex flex-wrap -mx-2 mb-2">
                                        <div className="w-full md:w-1/2 px-2 mb-4">
                                          <Text className="font-semibold text-gray-600">
                                            Risk Related to Lack of Information
                                          </Text>
                                          <Select
                                            placeholder="Is risk related to lack of Information"
                                            value={risk.risk_related_to_lack_of_info}
                                            onChange={(value) =>
                                              handleUpdateRiskField({
                                                area,
                                                riskIndex,
                                                field: 'risk_related_to_lack_of_info',
                                                value
                                              })
                                            }
                                            className="mt-1 w-full"
                                            disabled={
                                              workflowState === ProjectStatusEnum.PRODUCTION
                                                ? true
                                                : false
                                            }>
                                            <Option value={true}>Yes</Option>
                                            <Option value={false}>No</Option>
                                          </Select>
                                        </div>
                                        {risk.risk_related_to_lack_of_info && (
                                          <div className="w-full md:w-1/2 px-2 mb-4">
                                            <Text className="font-semibold text-gray-600">
                                              Risk Related to Lack of Information Description
                                            </Text>
                                            <Input.TextArea
                                              placeholder="Description for why risk related to lack of Information"
                                              defaultValue={
                                                risk.risk_related_to_lack_of_info_reason
                                              }
                                              onChange={(e) =>
                                                handleUpdateRiskField({
                                                  area,
                                                  riskIndex,
                                                  field: 'risk_related_to_lack_of_info_reason',
                                                  value: e.target.value
                                                })
                                              }
                                              autoSize={{ minRows: 2, maxRows: 4 }}
                                              className="mt-1 w-full"
                                              disabled={
                                                workflowState === ProjectStatusEnum.PRODUCTION
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>

                                      <div className="flex flex-wrap -mx-2 mb-2">
                                        <div className="w-full md:w-1/2 px-2 mb-4">
                                          <Text className="font-semibold text-gray-600">
                                            Mitigation Evidence
                                          </Text>
                                          <Select
                                            placeholder="Select Mitigation Evidence"
                                            value={risk.mitigation_evidence}
                                            onChange={(value) =>
                                              handleUpdateRiskField({
                                                area,
                                                riskIndex,
                                                field: 'mitigation_evidence',
                                                value
                                              })
                                            }
                                            className="mt-1 w-full"
                                            disabled={
                                              workflowState === ProjectStatusEnum.PRODUCTION
                                                ? true
                                                : false
                                            }>
                                            <Option value={false}>No evidence provided</Option>
                                            <Option value={true}>Yes, evidence provided</Option>
                                          </Select>
                                        </div>
                                      </div>

                                      <div>
                                        <Text className="font-semibold text-gray-600">
                                          Description
                                        </Text>
                                        <SimpleEditor
                                          initialData={risk.description}
                                          onChange={async (content) => {
                                            const updateFn = await updateRiskDescription(
                                              area,
                                              riskIndex,
                                              'description'
                                            );
                                            updateFn(content);
                                          }}
                                          editable={
                                            workflowState === ProjectStatusEnum.STAGING
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>

                                      <div>
                                        <Text className="font-semibold text-gray-600">
                                          Image Section (Optional)
                                        </Text>
                                        <ImageComponentSection
                                          imageSection={
                                            risk.image_exists
                                              ? {
                                                  url: risk.image_url,
                                                  description: risk.image_description
                                                }
                                              : {}
                                          }
                                          addSection={() =>
                                            handleUpdateRiskField({
                                              area,
                                              riskIndex,
                                              field: 'image_exists',
                                              value: true
                                            })
                                          }
                                          removeSection={() =>
                                            handleUpdateRiskField({
                                              area,
                                              riskIndex,
                                              field: 'image_exists',
                                              value: false
                                            })
                                          }
                                          addImage={(file) =>
                                            addProjectRiskImage({ id: risk.id, file })
                                          }
                                          removeImage={() =>
                                            removeProjectRiskImage({ id: risk.id })
                                          }
                                          descriptionChange={async (content) => {
                                            const updateFn = await updateRiskDescription(
                                              area,
                                              riskIndex,
                                              'image_description'
                                            );
                                            updateFn(content);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                  <Button
                                    type="dashed"
                                    icon={<PlusOutlined />}
                                    onClick={() => addRisk({ area })}
                                    disabled={
                                      workflowState === ProjectStatusEnum.PRODUCTION ? true : false
                                    }>
                                    Add Risk
                                  </Button>
                                </div>
                              ) : (
                                <div>
                                  <Text className="font-semibold text-gray-600">Reason:</Text>
                                  <SimpleEditor
                                    initialData={area.no_risks_identified_reason}
                                    onChange={(value) =>
                                      handleSafeGuardFieldChange({
                                        area,
                                        field: 'no_risks_identified_reason',
                                        value
                                      })
                                    }
                                    editable={
                                      workflowState === ProjectStatusEnum.STAGING ? true : false
                                    }
                                  />
                                </div>
                              )}
                            </Card>
                          ))}
                        </div>
                      ))}
                    </>
                  )
                },
                {
                  key: '2',
                  label: `${
                    find(esrData?.esr_sections, { section_type: 'further_research' })?.title
                  }`,
                  children: (
                    <>
                      <SimpleEditor
                        initialData={
                          find(esrData?.esr_sections, { section_type: 'further_research' })
                            ?.description
                        }
                        onChange={async (content) => {
                          const updatefn = await updateSectionField({
                            sectionType: 'further_research'
                          });
                          updatefn(content);
                        }}
                        editable={workflowState === ProjectStatusEnum.STAGING ? true : false}
                      />
                    </>
                  )
                },
                {
                  key: '3',
                  label: `${
                    find(esrData?.esr_sections, { section_type: 'methodology_n_sources' })?.title
                  }`,
                  children: (
                    <>
                      <SimpleEditor
                        initialData={
                          find(esrData?.esr_sections, { section_type: 'methodology_n_sources' })
                            ?.description
                        }
                        onChange={async (content) => {
                          const updatefn = await updateSectionField({
                            sectionType: 'methodology_n_sources'
                          });
                          updatefn(content);
                        }}
                        editable={workflowState === ProjectStatusEnum.STAGING ? true : false}
                      />
                    </>
                  )
                }
              ]}
              defaultActiveKey="1"
              className="my-2"
            />
          </div>
        </>
      ) : (
        <>
          {!loading && (
            <Button
              type="default"
              icon={<PlusOutlined />}
              style={{ color: 'black' }}
              onClick={() => addESR()}
              disabled={workflowState === ProjectStatusEnum.PRODUCTION ? true : false}>
              Start ESR screening
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default SafeguardSection;

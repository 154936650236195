import React, { useState, useEffect } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import { Form, Input, Button, message, Select, AutoComplete, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { getLocationTypes, getLocationById, updateLocation, getLocations } from '../../../api/location';
import './editlocation.css';

const { Option } = Select;

const EditLocationComponent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [locationTypes, setLocationTypes] = useState<string[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [searchOptions, setSearchOptions] = useState<any[]>([]);
  const [showParentIdField, setShowParentIdField] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams<{ id }>();
  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const types = await getLocationTypes();
        setLocationTypes(types.response);

        const locationsData = await getLocations();
        setLocations(locationsData.response);
        setSearchOptions(locationsData.response.map(location => ({ value: location.display_name, key: location.id })));

        const locationDetails = await getLocationById(id);
        setLoading(false);
        form.setFieldsValue({ ...locationDetails.response, parent_id: locationsData.response.find(location => location.id === locationDetails.response.parent_id)?.id });
        setShowParentIdField(locationDetails.response.type);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, [id, form]);


  const handleSearch = (value: string) => {
    const filteredOptions = locations.filter(location =>
      location.name.toLowerCase().includes(value.toLowerCase())
    ).map(location => ({ value: location.name, key: location.id }));
    setSearchOptions(filteredOptions);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const { parent_id, ...restValues } = values;
      let parent;
      if (!isUndefined(parent_id)) {
        parent = locations.find(location => location.id === values.parent_id);
      }
      await updateLocation(id, { ...restValues, ...(!isEmpty(parent) && { parent_id: parent.id }), name: values.display_name });
      message.success({
        content: 'Location updated successfully',
        duration: 4,
        style: { textAlign: 'right' },
      });
      navigate('/locations');
    } catch (error: any) {
      console.error('Error updating location:', error);
      message.error({
        content: error.message,
        duration: 4,
        style: { textAlign: 'right' },
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  const handleLocationTypeChange = (locationType) => {
    form.setFieldValue('type', locationType);
    if (locationType === 'region') {
      setShowParentIdField(false);
    }
    setShowParentIdField(locationType);
  };

  const getLocationParents = (type) => {
    return locations
      .filter((location) => location.type === type)
      .map((parent) => (
        <Option key={parent.id} value={parent.id}>
          {parent.display_name}
        </Option>
      ));
  }

  return (
    <div className="edit-location-container">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <div className="form-container">
        <h1>Edit Location</h1>
        <Form
          form={form}
          name="editLocationForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="display_name"
            rules={[{ required: true, message: 'Please input the name of the location!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Alias"
            name="alias"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: 'Please select the type of location!' }]}
          >
            <Select onChange={handleLocationTypeChange}>
              {locationTypes.map(type => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {form.getFieldValue('type') === 'state' &&
            <Form.Item label="Country" name="parent_id">
              <Select placeholder="Select Country" showSearch
                filterOption={(inputValue, option) =>
                  option?.children?.toString().toLowerCase().includes(inputValue.toLowerCase()) ? true : false
                }>
                {getLocationParents('country')}
              </Select>
            </Form.Item>
          }

          {form.getFieldValue('type') === 'country' &&
            <Form.Item label="Region" name="parent_id">
              <Select placeholder="Select Region" showSearch
                filterOption={(inputValue, option) =>
                  option?.children?.toString().toLowerCase().includes(inputValue.toLowerCase()) ? true : false
                }>
                {getLocationParents('region')}
              </Select>
            </Form.Item>
          }
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EditLocationComponent;

import { AxiosResponse } from 'axios';
import axiosInstance, { BASE_URL } from './axios';

export const getProjects = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('/project/all', {
      headers: {
        projectWorkflowState: 'staging',
      }
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching projects: ${error}`);
  }
};

export const getProjectNames = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('/project/all/names');
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching projects: ${error}`);
  }
};

export const getProjectById = async (id: string, cp_id: number): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/project/catcher/${id}/cp_id/${cp_id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching project by id: ${error}`);
  }
};

export const getProjectRatingById = async (id: string, cp_id: number): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/project/rating/${id}/${cp_id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching project rating by id: ${error}`);
  }
};

export const updateProjectRatingById = async (id: string, cp_id: number, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`/project/rating/${id}/${cp_id}`, data);
    return response.data;
  } catch (error) {
    throw new Error(`Error updating project rating by id: ${error}`);
  }
};

export const updateRatings = async (url: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.put(`/project/ratings`, {
      url,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error updating project ratings: ${error}`);
  }
};

export const createProject = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`/project`, data);
    return response.data;
  } catch (error: any) {
    let errorMessage = 'Internal service error';
    if (error.response?.status === 400) {
      errorMessage = error.response?.data?.message;
    }
    throw new Error(`Error creating the project: ${errorMessage}`);
  }
};

export const cloneProject = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`/project/clone`, data);
    return response.data;
  } catch (error: any) {
    let errorMessage = 'Internal service error';
    if (error.response?.status === 400) {
      errorMessage = error.response?.data?.message;
    }
    throw new Error(`Error cloning the project: ${errorMessage}`);
  }
};

export const createExpertCalibrationProject = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.put(`/project/expert_calibration/${id}`);
    return response.data;
  } catch (error: any) {
    let errorMessage = 'Internal service error';
    if (error.response?.status === 400) {
      errorMessage = error.response?.data?.message;
    }
    throw new Error(`Error creating the expert calibration project: ${errorMessage}`);
  }
};

export const removeExpertCalibrationProject = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`/project/expert_calibration/${id}`);
    return response.data;
  } catch (error: any) {
    let errorMessage = 'Internal service error';
    if (error.response?.status === 400) {
      errorMessage = error.response?.data?.message;
    }
    throw new Error(`Error removing the expert calibration project: ${errorMessage}`);
  }
};

export const createProjectCreditingPeriod = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`/project/cp`, data);
    return response.data;
  } catch (error: any) {
    let errorMessage = 'Internal service error';
    if (error.response?.status === 400) {
      errorMessage = error.response?.data?.message;
    }
    throw new Error(`Error creating the project crediting period: ${errorMessage}`);
  }
};

export const updateProject = async (id: string, cp_id: number, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`/project/${id}/cp_id/${cp_id}`, data);
    return response.data;
  } catch (error: any) {
    let errorMessage = 'Internal service error';
    if (error.response?.status === 400) {
      errorMessage = error.response?.data?.message;
    }
    throw new Error(`Error updating the project: ${errorMessage}`);
  }
};

export const moveProject = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`/project_workflow/move`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error moving the project: ${errorMessage}`);
  }
};

export const getHistory = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/project_workflow/review/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error fetching the project history: ${errorMessage}`);
  }
};

export const downloadProjectCSV = async (): Promise<void> => {
  try {
    const response: AxiosResponse<Blob> = await axiosInstance.get('/project/download-csv', {
      responseType: 'blob'
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error downloading CSV: ${errorMessage}`);
  }
};

export const getRatingChanges = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/project/ratings/changes/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error fetching the rating changes: ${errorMessage}`);
  }
};

export const createRatingChange = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`/project/ratings/changes/`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error creating the rating changes: ${errorMessage}`);
  }
};

export const updateRatingChange = async (id: any, body: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`/project/ratings/changes/${id}`, body);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error updating the rating changes: ${errorMessage}`);
  }
};

export const deleteRatingChange = async (id: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`/project/ratings/changes/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error deleting the rating changes: ${errorMessage}`);
  }
};



import React, { useState, useEffect } from 'react';
import { Table, Button, Spin, Input, Tabs } from 'antd';
import { LoadingOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import './view.css';
import type { TableColumnsType } from 'antd';
import { getProjectTypes, getProjectTypeGroups } from '../../api/project_type';

const { TabPane } = Tabs;

interface ProjectType {
  id: number;
  name: string;
  display_name: string;
  project_group: {
    id: number;
    name: string;
    display_name: string;
  };
}

interface ProjectGroup {
  id: number;
  name: string;
  display_name: string;
}

const projectTypeColumns: TableColumnsType<ProjectType> = [
  {
    title: 'Name',
    dataIndex: 'display_name',
    key: 'name',
  },
  {
    title: 'Project Group',
    dataIndex: ['project_group', 'display_name'],
    key: 'project_group',
  }
];

const projectTypeGroupColumns: TableColumnsType<ProjectGroup> = [
  {
    title: 'Name',
    dataIndex: 'display_name',
    key: 'name',
  },
];

const ViewProjectTypeMetaComponent: React.FC<{ activeTab?: string }> = ({ activeTab }) => {
  const defaultActiveKey = activeTab === 'project_groups' ? '2' : '1';
  const [loading, setLoading] = useState<boolean>(false);
  const [projectTypes, setProjectTypes] = useState<ProjectType[]>([]);
  const [projectGroups, setProjectGroups] = useState<ProjectGroup[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const projectTypesData = await getProjectTypes();
        setProjectTypes(projectTypesData.response);
        const projectGroupsData = await getProjectTypeGroups();
        setProjectGroups(projectGroupsData.response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const filteredProjectTypes = projectTypes.filter(projectType =>
    projectType.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const filteredProjectGroups = projectGroups.filter(projectGroup =>
    projectGroup.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="table-container">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <Tabs defaultActiveKey={defaultActiveKey}>
        <TabPane tab="Project Types" key="1">
          <div className="table-controls">
            <div>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search Project Type"
                value={searchText}
                onChange={e => handleSearch(e.target.value)}
                style={{ marginBottom: '16px', width: '50%' }}
              />
            </div>
            <Link to="/project_types/create">
              <Button type="primary" icon={<PlusOutlined />} style={{ marginRight: '8px' }}>Create Project Type</Button>
            </Link>
          </div>
          <Table
            columns={projectTypeColumns}
            dataSource={filteredProjectTypes.map(projectType => ({ ...projectType, key: projectType.id }))}
            onRow={(record: ProjectType) => ({
              onClick: () => navigate(`/project_types/edit/${record.id}`),
              style: { cursor: 'pointer' }
            })}
          />
        </TabPane>
        <TabPane tab="Project Groups" key="2">
          <div className="table-controls">
            <div>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search Project Group"
                value={searchText}
                onChange={e => handleSearch(e.target.value)}
                style={{ marginBottom: '16px', width: '50%' }}
              />
            </div>
            <Link to="/project_groups/create">
              <Button type="primary" icon={<PlusOutlined />} style={{ marginRight: '8px' }}>Create Project Group</Button>
            </Link>
          </div>
          <Table
            columns={projectTypeGroupColumns}
            dataSource={filteredProjectGroups.map(projectGroup => ({ ...projectGroup, key: projectGroup.id }))}
            onRow={(record: ProjectGroup) => ({
              onClick: () => navigate(`/project_groups/edit/${record.id}`),
              style: { cursor: 'pointer' }
            })}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ViewProjectTypeMetaComponent;

import { Button, Card, Input, InputNumber, Popconfirm, Select, Space } from 'antd';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { debounce } from 'lodash';
import { deleteRatingTableData, postRatingTableData, putRatingTableData } from '../../api';
import ContentEditor from '../ContentEditor';
import {
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
  QuestionCircleOutlined,
  DeleteFilled
} from '@ant-design/icons';
import { isProjectLive } from '../../utils';

const { Option, OptGroup } = Select;

type StatusOptionsProps = {
  type: 'tag' | 'status';
  defaultValue: any;
  disabled: any;
  onChange: (value: any) => void;
};

const StatusOptions: React.FC<StatusOptionsProps> = ({
  type,
  disabled,
  defaultValue,
  onChange
}) => {
  return (
    <Select
      disabled={disabled}
      className='w-48'
      listHeight={400}
      size='large'
      defaultValue={defaultValue}
      onChange={onChange}
    >
      <Option value='8' label='8'>
        <Space>
          <div className='ml-1 text-black'>N/A</div>
        </Space>
      </Option>
      <OptGroup key='risk' label='Risk'>
        <Option value='0' label='0'>
          <Space style={{ color: '#1FA21C' }}>
            <CheckCircleTwoTone
              style={{ fontSize: '18px' }}
              twoToneColor='#1FA21C'
            />
            <div className='ml-1'>1/Low Risk</div>
          </Space>
        </Option>
        <Option value='1' label='1'>
          <Space style={{ color: '#86BD09' }}>
            <CheckCircleTwoTone
              style={{ fontSize: '18px' }}
              twoToneColor='#86BD09'
            />
            <div className='ml-1'>2/Low-Medium Risk</div>
          </Space>
        </Option>
        <Option value='2' label='2'>
          <Space style={{ color: '#EBC606' }}>
            <MinusCircleTwoTone
              style={{ fontSize: '18px' }}
              twoToneColor='#EBC606'
            />
            <div className='ml-1'>3/Medium Risk</div>
          </Space>
        </Option>
        <Option value='3' label='3'>
          <Space style={{ color: '#FF8310' }}>
            <CloseCircleTwoTone
              style={{ fontSize: '18px' }}
              twoToneColor='#FF8310'
            />
            <div className='ml-1'>4/Medium-High Risk</div>
          </Space>
        </Option>
        <Option value='4' label='4'>
          <Space style={{ color: '#EF5928' }}>
            <CloseCircleTwoTone
              style={{ fontSize: '18px' }}
              twoToneColor='#EF5928'
            />
            <div className='ml-1'>5/High Risk</div>
          </Space>
        </Option>
      </OptGroup>
      <OptGroup key=''>
        <Option value='5' label='5'>
          <Space style={{ color: '#1FA21C' }}>
            <CheckCircleTwoTone
              style={{ fontSize: '18px' }}
              twoToneColor='#1FA21C'
            />
            <div className='ml-1'>Yes</div>
          </Space>
        </Option>
        <Option value='6' label='6'>
          <Space style={{ color: '#EBC606' }}>
            <MinusCircleTwoTone
              style={{ fontSize: '18px' }}
              twoToneColor='#EBC606'
            />
            <div className='ml-1'>Maybe</div>
          </Space>
        </Option>
        <Option value='7' label='7'>
          <Space style={{ color: '#EF5928' }}>
            <CloseCircleTwoTone
              style={{ fontSize: '18px' }}
              twoToneColor='#EF5928'
            />
            <div className='ml-1'>No</div>
          </Space>
        </Option>
      </OptGroup>
    </Select>
  );
};


type TableEditorProps = {
  saveData: (data: any) => void;
  project: any;
  item: any;
  section: any;
  entityId: string;
  queryKey: string;
  id: any;
};

const TableEditor: React.FC<TableEditorProps> = ({ saveData, project, section, entityId, queryKey, item, id }) => {
  const { rating_table_attributes } = item;
  const client = useQueryClient();
  let rows = [] as any;
  let columns = [] as any;
  rating_table_attributes.forEach((attr) => {
    columns.push({
      key: attr.name,
      label: attr.value
    });
    //sort by row_id
    attr.rating_table_data.sort(
      (a, b) => a.row_id - b.row_id
    ).forEach((rowData, rowIndex) => {
      rows?.[rowIndex]
        ? (rows[rowIndex][attr.name] = {
          id: rowData.id,
          value: rowData.value,
          ckeditor_content_doc: rowData.ckeditor_content_doc
        })
        : (rows[rowIndex] = {
          [attr.name]: {
            id: rowData.id,
            value: rowData.value,
            ckeditor_content_doc: rowData.ckeditor_content_doc
          }
        });
    });
  });
  columns.sort((a, b) => ['type', 'status', 'description'].indexOf(a.key) - ['type', 'status','description'].indexOf(b.key));

  const {
    mutate: updateRatingTableData,
    isLoading,
    error
  } = useMutation(putRatingTableData, {
    onSuccess: (data) => {
      client.invalidateQueries({
        queryKey: [queryKey]
      });
    }
  });

  const {
    mutate: addRatingTableData,
    isLoading: isAdding,
    error: addError
  } = useMutation(postRatingTableData, {
      onSuccess: (data) => {
          client.invalidateQueries({
              queryKey: [queryKey]
          });
      }
  });

  const {
    mutate: removeRatingTableData,
    isLoading: isDeleting,
    error: deleteError
} = useMutation(deleteRatingTableData, {
    onSuccess: (data) => {
        client.invalidateQueries({
            queryKey: [queryKey]
        });
    }
});

  const onStatusChange = (r, key) => (value) => {
    const { id } = r[key];
    updateRatingTableData({
      ratingTableDataId: id,
      data: {
        value: `${value}`
      }
    });
  };

  const onDebouncedTitleChange = (r, key) => debounce(onTitleChange(r, key), 800);
  const onTitleChange = (r, key) => (e) => {
    const { id } = r[key];
    updateRatingTableData({
      ratingTableDataId: id,
      data: {
        value: e.target.value
      }
    });
  };

  const handleAddCard = () => {
    addRatingTableData(rating_table_attributes.map((attr) => {
        return {
          rating_table_attribute_id: attr.id,
          value: '',
          row_id: rows.length
        }
      })
    );
  };

  const onRemoveCard = (r) => () => {
    Object.keys(r).forEach((key) => {
      const { id } = r[key];
      removeRatingTableData(id);
    });
  };

  return (
    <div className='flex flex-col gap-4'>
      {rows.map((r, ri) => {
        return (
          <Card size='small' key={ri}>
            <div className='flex flex-col gap-2'>
              {columns.map((c, ci) => {
                if (c.key === 'type') {
                  return (
                    <div className='flex flex-row gap-1 items-center' key={ci}>
                      <div className='w-20'>Title</div>
                      <div className='flex-auto'>
                        <Input
                          defaultValue={r[c.key].value}
                          disabled={isProjectLive(project)}
                          onChange={onDebouncedTitleChange(r, c.key)}
                        />
                      </div>
                      <Popconfirm
                          title={`"Remove Card - ${r[c.key].value}`}
                          description="Are you sure to remove this card"
                          icon={(
                              <QuestionCircleOutlined style={{ color: 'red' }} />
                          )}
                          onConfirm={onRemoveCard(r)}
                          okText="Yes, Delete the card"
                          cancelText="No, do not delete"
                      >
                          <Button type="link" danger icon={<DeleteFilled />}>
                              Remove
                          </Button>
                      </Popconfirm>
                    </div>
                  );
                } else if (c.key === 'status') {
                  return (
                    <div className='flex flex-row gap-1 items-center' key={ci}>
                      <div className='w-20'>{c.label}</div>
                      <StatusOptions
                        disabled={isProjectLive(project)}
                        type={c.key}
                        defaultValue={r[c.key].value}
                        onChange={onStatusChange(r, c.key)}
                      />
                    </div>
                  );
                } else if (c.key === 'description') {
                  return (
                    <div className='flex w-5/6 flex-row gap-1 items-center' key={ci}>
                      <div className='w-20'>{c.label}</div>
                      <ContentEditor
                        entityId={entityId}
                        project={project}
                        queryKey={queryKey}
                        saveData={saveData}
                        sectionType={'table'}
                        tableDataId={r[c.key].id}
                        id={`${id}_${r[c.key].id}`}
                        data={r[c.key].ckeditor_content_doc}
                      />
                    </div>
                  );
                }

                return null;
              })}
            </div>
          </Card>
        );
      })}
      <Button type="default" className='w-32'
        onClick={handleAddCard}>
        Add Card
      </Button>
    </div>
  );
};

export default TableEditor;

import { Card, InputNumber, Select, Space, UploadProps, message, Upload, Image } from 'antd';
import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from 'react-query';
import { isProjectLive } from '../../utils';
import { BASE_URL } from '../../api/axios';
import { postRatingBlurbSectionImage } from '../../api';

const { Dragger } = Upload;


const uploadProps: (id: Number | String, client: any, queryKey: String, disabled: boolean) => (UploadProps) = (id, client, queryKey, disabled) => ({
    name: 'file',
    multiple: false,
    maxCount: 1,
    accept: 'image/*',
    disabled,
    customRequest: ({
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials,
    }) => {
        const formData = new FormData();
        formData.append('file', file);

        postRatingBlurbSectionImage({
            ratingBlurbSectionId: id,
            file: formData
        }).then(({ data: response }) => {
            if (onSuccess) {
                onSuccess('done'); // Fixed the order of arguments
            }
            client.invalidateQueries({
                queryKey: [queryKey]
            });
        }).catch(onError);
    }
});
type ImageUploaderProps = {
  saveData: (data: any) => void;
  project: any;
  item: any;
  section: any;
  entityId: string;
  queryKey: string;
  id: any;
};

const ImageUploader: React.FC<ImageUploaderProps> = ({ saveData, project, section, entityId, queryKey, item, id }) => {
    const client = useQueryClient();
    const disabled = isProjectLive(project);
    const props = uploadProps(id, client, queryKey, disabled);
    return (
        <div className='flex-1 flex-col flex'>
            {item.image_url && 
                <Card
                    style={{ width: 300 }}
                    cover={
                        <Image
                            src={`${item.image_url}`}
                            alt={item.alttext}
                        />
                    }
                >
                </Card>
            } 
            <Dragger {...props}>
                <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                </p>
                <p className='ant-upload-text'>Click or drag an image to this area to upload</p>
                <p className='ant-upload-hint'>
                    Please ensure that the image size is less than 500kb.
                </p>
            </Dragger>
        </div>
    );
};

export default ImageUploader;

import { AxiosResponse } from 'axios';
import axiosInstance, { BASE_URL } from './axios';

export const getLocations = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('/locations');
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching location data ${error}`);
  }
};

export const getLocationById = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/locations/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching location data ${error}`);
  }
};

export const getLocationTypes = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('/locations/types');
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching location data ${error}`);
  }
};

export const createLocation = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post('/locations', data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error creating the location: ${errorMessage}`);
  }
};

export const updateLocation = async (id: number, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`/locations/${id}`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error updating the location: ${errorMessage}`);
  }
};

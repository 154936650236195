import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import { Form, Input, Spin, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { getProjectById, updateProject } from '../../../api/project';
import { getSDGCertificates } from '../../../api/rating';
import { getRatingOptions } from '../../../config/rating.config';


import { Option } from 'antd/es/mentions';
import dayjs from 'dayjs';


interface SDGRatingFormProps {
  form: any;
  updateParentDetails: any;
}

const SDGRatingForm: React.FC<SDGRatingFormProps> = ({ form, updateParentDetails }) => {

  const { id, creditingPeriodId } = useParams<{ id, creditingPeriodId: string }>();
  const [sdgCertificates, setSdgCertificates] = useState([]);
  const [creditingPeriod, setCreditingPeriod] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const ratingOptions = getRatingOptions();

  useEffect(() => {
    async function fetchProject() {
      setLoading(true);
      try {
        const projectData = await getProjectById(id, Number(creditingPeriodId));
        const sdgCertificateData = await getSDGCertificates();
        setSdgCertificates(sdgCertificateData.response.map(sdgCertificate => sdgCertificate.code))
        const creditingPeriod = projectData.response.project_crediting_periods.find(creditingPeriod => creditingPeriod.crediting_period_id === Number(creditingPeriodId));
        setCreditingPeriod(creditingPeriod);
        updateParentDetails({
          sdgCertificates: sdgCertificateData.response,
        });
        setLoading(false);
        const sdgFields = {};
        for (let i = 1; i <= 17; i++) {
          const sdgKey = `sdg_${i}_score`;
          if (creditingPeriod?.ratings[sdgKey] !== undefined) {
            sdgFields[sdgKey] = creditingPeriod.ratings[sdgKey];
          }
        }
        form.setFieldsValue({
          sdgCertificate: creditingPeriod.ratings.sdg_certificate?.code,
          sdgImpactRating: creditingPeriod.ratings.sdg_impact_rating ? `+${creditingPeriod.ratings.sdg_impact_rating}` : '',
          sdgImpactRatingNotes: creditingPeriod.ratings.sdg_impact_rating_notes,
          ...sdgFields,
        });
      } catch (error) {
        setLoading(false);
        console.error('Error fetching project:', error);
      }
    }

    fetchProject();
  }, [id, form]);

  return (
    <>
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <div className='flex gap-4'>
        <Form.Item
          label="SDG Rating"
          name="sdgImpactRating"
          rules={[{ required: false, message: 'Please input SDG Impact Rating!' }]}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="SDGCertificate"
          name="sdgCertificate"
          className='w-48'
          rules={[{ required: false, message: 'Please select SDGCertificate!' }]}
        >
          <Select
          >
            {sdgCertificates.map((sdgCertificate: string) => (
              <Option
                key={sdgCertificate}
                value={sdgCertificate}
                style={{ fontWeight: form.getFieldValue('sdgCertificate') === sdgCertificate ? 'bold' : 'normal' }}
              >{sdgCertificate}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <Form.Item
        label="SDG Notes (Internal use)"
        name="sdgImpactRatingNotes"
      >
        <Input.TextArea />
      </Form.Item>
      <div className='flex flex-wrap gap-x-4'>
        {[...Array(17)].map((_, index) => {
          const sdgNumber = index + 1;
          const fieldName = `sdg_${sdgNumber}_score`;
          return (
            <Form.Item
              key={`sdg_${sdgNumber}`}
              label={`SDG ${sdgNumber} Score`}
              name={fieldName}
              rules={[{ required: false, message: `Please input SDG ${sdgNumber} Score!` }]}
            >
              <Input type="number" disabled={true} />
            </Form.Item>
          );
        })}
      </div>
    </>
  );
};

export default SDGRatingForm;

import React, { useCallback } from 'react';
import Map, { NavigationControl } from 'react-map-gl';
import { v4 as uuidv4 } from 'uuid';
import maplibregl from 'maplibre-gl';
import { debounce, sortBy } from 'lodash';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Button, Checkbox, Input, ColorPicker } from 'antd';

type MapViewerProps = {
    maptilerLink: string;
    saveData: any;
    maptilerViewState: any;
    maptilerLayers: any;
    disabled: boolean;
};

const MapViewer: React.FC<MapViewerProps> = ({ maptilerLink, maptilerViewState, maptilerLayers, saveData, disabled }) => {
    const mapRef = React.useRef<any>(null);
    const onMapLoaded = () => {
        const map = mapRef.current.getMap();
        if (!maptilerLayers) {
            const mapTilerLayers = map.getStyle().layers.filter(layer => {
                return layer.id?.includes('Calyx_');
            });
            saveData({
                maptiler_layers: sortBy(mapTilerLayers, 'type').map(layer => {
                    let legend;
                    if (layer.type === 'raster') {
                        legend = [{
                            name: layer.id?.replace('Calyx_', ''),
                            style: {
                                'background-color': '#F6AB30'
                            }
                        }, {
                            name: layer.id,
                            style: {
                                'background-color': '#BE5242'
                            }
                        }];
                    } else {
                        legend = [{
                            name: layer.id?.replace('Calyx_', ''),
                            style: {
                                'outline-width': '1px',
                                'outline-style': 'solid',
                                'background-color': layer.paint['fill-color'],
                                'outline-color': layer.paint['fill-outline-color']
                            }
                        }];
                    }
                    return {
                        ...layer,
                        legend
                    };
                })
            })
        }
    };

    const onZoomEnd = useCallback((evt) => {
        const viewState = evt.viewState;
        if (viewState) {
            saveData({
                maptiler_viewstate: {
                    ...maptilerViewState,
                    ...viewState
                }
            });
        }
    }, [mapRef.current]);

    const onAddCustomMapLegend = () => {
        const newLegend = {
            id: uuidv4(),
            type: 'custom_legend',
            layout: {
                visibility: 'visible'
            },
            legend: [{
                name: ' ',
                style: {
                    'background-color': '#F6AB30'
                },
                removable: true
            }]
        };
        saveData({
            maptiler_layers: maptilerLayers.concat(newLegend)
        });
    };

    const onRemoveLegend = (layer, i, j) => () => {
        saveData({
            maptiler_layers: maptilerLayers.map((maptilerLayer) => {
                if (maptilerLayer.id === layer.id) {
                    return false;
                }

                return maptilerLayer;
            }).filter(Boolean)
        });
    };

    const onMapTilerLayerChange = (layer) => (evt) => {
        const map = mapRef.current.getMap();
        if (evt.target.checked) {
            map.setLayoutProperty(layer.id, 'visibility', 'visible');
        } else {
            map.setLayoutProperty(layer.id, 'visibility', 'none');
        }
        saveData({
            maptiler_layers: maptilerLayers.map((maptilerLayer) => {
                if (maptilerLayer.id === layer.id) {
                    return {
                        ...maptilerLayer,
                        layout: {
                            ...maptilerLayer.layout,
                            visibility: evt.target.checked ? 'visible' : 'none'
                        }
                    }
                }

                return maptilerLayer;
            })
        });
    };

    const onDebouncedLegendChange = (layer, j) => debounce(onLegendChange(layer, j), 800);
    const onLegendChange = (layer, j) => (evt) => {
        saveData({
            maptiler_layers: maptilerLayers.map((maptilerLayer) => {
                if (maptilerLayer.id === layer.id) {
                    return {
                        ...maptilerLayer,
                        legend: maptilerLayer.legend.map((legend, i) => {
                            if (i === j) {
                                return {
                                    ...legend,
                                    name: evt.target.value
                                };
                            }

                            return legend;
                        })
                    };
                }

                return maptilerLayer;
            })
        });
    };

    const onLegendColorChange = (layer, j) => (color) => {
        saveData({
            maptiler_layers: maptilerLayers.map((maptilerLayer) => {
                if (maptilerLayer.id === layer.id) {
                    return {
                        ...maptilerLayer,
                        legend: maptilerLayer.legend.map((legend, i) => {
                            if (i === j) {
                                return {
                                    ...legend,
                                    style: {
                                        ...legend.style,
                                        'background-color': color.toHexString()
                                    }
                                };
                            }

                            return legend;
                        })
                    };
                }

                return maptilerLayer;
            })
        });
    };

    const renameKeys = (obj) => {
        const keyValues = Object.keys(obj).map(key => {
            var camelCased = key.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
            return { [camelCased]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
    }

    if (!maptilerLink) return <div className='mt-3'>Please enter a maptiler link in the input box above</div>;
    return (
        <div className='flex'>
            <div className='w-1/2 h-72 m-3'>
                <Map
                    ref={mapRef}
                    initialViewState={maptilerViewState || undefined}
                    onLoad={onMapLoaded}
                    mapStyle={maptilerLink}
                    mapboxAccessToken='pk.eyJ1IjoiYWNlbWlsIiwiYSI6ImNsbG0xc3c2cDJjN3IzcWw2dm5zeGc0MWsifQ.CE5ZilaZfBicXLzx0uFHWA'
                    scrollZoom
                    boxZoom
                    dragRotate
                    dragPan
                    keyboard
                    onZoomEnd={onZoomEnd}
                />
            </div>
            <div className='flex flex-col gap-1 m-3 flex-1'>
                {(maptilerLayers || []).map((layer, i) => {
                    return (
                        <div key={i} className='flex flex-col'>
                            {layer?.type !== 'custom_legend' && 
                                (<Checkbox
                                    disabled={disabled}
                                    checked={layer.layout?.visibility === 'visible'}
                                    onChange={onMapTilerLayerChange(layer)}
                                >
                                    Show layer on map
                                </Checkbox>)
                            }
                            {layer.legend && layer.legend.map((legend, j) => {
                                console.log(legend, legend.style['background-color']);
                                return (
                                    <div key={j} className='ml-3 flex my-1 items-center gap-1'>
                                        {legend.removable ? (
                                            <ColorPicker
                                                disabled={disabled}
                                                value={legend.style['background-color']}
                                                showText
                                                onChangeComplete={onLegendColorChange(layer, j)}
                                            />
                                        ) : (
                                            <div className='w-4 h-4 mr-2' style={renameKeys(legend.style || {})}></div>
                                        )}
                                        <Input className='w-3/4' disabled={disabled} defaultValue={legend.name} onChange={onDebouncedLegendChange(layer, j)} />
                                        {legend.removable && <Button disabled={disabled} onClick={onRemoveLegend(layer, i, j)}>Remove</Button>}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                <Button className='mt-2 w-1/2' onClick={onAddCustomMapLegend}>
                    Add custom legend
                </Button>
            </div>
        </div>
    );
};

export default MapViewer;
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Form, Input, DatePicker, Select, Button, Space, Typography, message, Tooltip, Spin, Modal, Divider } from 'antd';
import { PlusOutlined, DeleteOutlined, InfoCircleOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { getProjectById, createProjectCreditingPeriod } from '../../../api/project';

const { Option } = Select;
const { Title, Text } = Typography;

const { RangePicker } = DatePicker;

interface CreditingPeriodFormProps {
  form: any;
}

const CreditingPeriodForm: React.FC<CreditingPeriodFormProps> = ({ form }) => {
  const [vintageYearsList, setVintageYearsList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { id, creditingPeriodId } = useParams<{ id, creditingPeriodId: string }>();
  const [creditingPeriod, setCreditingPeriod] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [formcp] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchProject() {
      setLoading(true);
      try {
        const projectData = await getProjectById(id, Number(creditingPeriodId));
        setLoading(false);
        const creditingPeriod = projectData.response.project_crediting_periods.find(creditingPeriod => creditingPeriod.crediting_period_id === Number(creditingPeriodId));
        setCreditingPeriod(creditingPeriod);
        const creditingPeriodDuration: any[] = [];
        if (creditingPeriod.start_date) {
          creditingPeriodDuration.push(dayjs(creditingPeriod.start_date));
        }
        if (creditingPeriod.end_date) {
          creditingPeriodDuration.push(dayjs(creditingPeriod.end_date));
        }
        const vintageYearsList: any[] = [];
        if (!isEmpty(creditingPeriod?.registry_vintage_year)) {
          vintageYearsList.push(
            ...creditingPeriod.registry_vintage_year.map((vintage: any) => {
              const registryVintageDuration: any[] = [];
              if (vintage.start_date) {
                registryVintageDuration.push(dayjs(vintage.start_date));
              }
              if (vintage.end_date) {
                registryVintageDuration.push(dayjs(vintage.end_date));
              }
              return {
                year: dayjs(`${vintage.year}-01-01`),
                duration: registryVintageDuration,
              };
            }),
          );
        }
        setVintageYearsList(vintageYearsList);
        form.setFieldsValue({
          creditingPeriodDuration,
          creditingPeriodNarration: creditingPeriod.title,
          vintageYears: vintageYearsList,
          verifiedCredits: creditingPeriod.verified_credits,
        });
      } catch (error) {
        setLoading(false);
        console.error('Error fetching project:', error);
      }
    }

    fetchProject();
  }, [id, form]);

  const handleAddCreditingPeriod = () => {
    setOpen(true);
  };

  const handleModalOk = async () => {
    try {
      const formatDate = date => dayjs(date).format('YYYY-MM-DD');
      const formData = formcp.getFieldsValue();
      await formcp.validateFields();
      try {
        setOpen(false);
        setLoading(true);
        const response = await createProjectCreditingPeriod({
          creditingPeriodId: creditingPeriod.id,
          creditingPeriodDateType: 'date',
          creditingPeriodStartDate: formatDate(formData.creditingPeriodDuration[0]),
          creditingPeriodEndDate: formatDate(formData.creditingPeriodDuration[1]),
          ...(formData.creditingPeriodNarration && {
            creditingPeriodNarration: formData.creditingPeriodNarration,
          }),
        });
        setLoading(false);
        message.success('Created the crediting period successfully');
        setLoading(true);
        navigate(`/project/edit/${response.response?.project_id}/cp/${response.response?.crediting_period_id}/step/0`, {
          replace: true,
        });
        await new Promise(resolve => setTimeout(resolve, 500));
        window.location.reload();
      } catch (err: any) {
        console.log(`Error creating project crediting period: ${err}`);
        setLoading(false);
        message.error({
          content: err.message || 'Unable to create crediting period',
          duration: 4,
          style: { textAlign: 'right' },
        });
      }
    } catch (err) {
      message.error({
        content: 'Please fill in all required fields before proceeding.',
        duration: 4,
        style: { textAlign: 'right' },
      });
    }
  };

  const handleModalCancel = () => {
    setOpen(false);
  };

  const addVintageYear = () => {
    const newVintageYears = [...vintageYearsList, { year: null, duration: null }];
    setVintageYearsList(newVintageYears);
    form.setFieldsValue({ vintageYears: newVintageYears });
  };

  const handleVintageYearChange = (index: number, field: string, value: any) => {
    const updatedYears = [...form.getFieldValue('vintageYears')];
    if (field === 'duration') {
      updatedYears[index] = { ...updatedYears[index]};
      const registryVintageDuration: any[] = [];
      if (value) {
        registryVintageDuration.push(dayjs(value[0]));
        registryVintageDuration.push(dayjs(value[1]));
      }
      updatedYears[index].duration = registryVintageDuration;
    } else {
      updatedYears[index] = { ...updatedYears[index], [field]: value };
    }
    setVintageYearsList(updatedYears);
    form.setFieldsValue({ vintageYears: updatedYears });
  };

  const removeVintageYear = (index: number) => {
    setVintageYearsList(vintageYearsList.filter((_, i) => i !== index));
    form.setFieldsValue({ vintageYears: vintageYearsList.filter((_, i) => i !== index) });
  };


  return (
    <>
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <Modal
          title="Add Crediting Period"
          open={open}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          width={800}
        >
          <Form
            form={formcp}
            layout="vertical">
            <Form.Item
              name="creditingPeriodDuration"
              label="Duration"
              rules={[{
                required: true,
                message: 'Please input time Period for this rating!'
              }]}
              style={{ marginTop: '15px', marginBottom: '15px' }}
            >
              <RangePicker picker='date' />
            </Form.Item>
            <Form.Item
              label="Narration"
              name="creditingPeriodNarration">
              <Input placeholder="Please input the narration for this time period" />
            </Form.Item>
          </Form>
      </Modal>
      <div className='flex items-center justify-between' style={{ marginTop: '10px', marginBottom: '20px' }}>
        <div className='flex items-center'>
          <Title level={5} style={{ margin: 0 }}>Time period for this rating</Title>
          <Tooltip
            title='This is the start date on the first verified monitoring report and the end of the most recent monitoring report that the rating covers'
            placement="right"
            overlayInnerStyle={{ width: '400px' }}
          >
            <InfoCircleOutlined style={{ marginLeft: 8, cursor: 'pointer' }} />
          </Tooltip>
        </div>
        <div style={{ textAlign: 'right' }}>
          <Button
            type="default"
            icon={<PlusCircleOutlined />}
            onClick={handleAddCreditingPeriod}
          >
            Add Crediting Period
          </Button>
        </div>
      </div>

      <div className='flex gap-4'>
        <Form.Item
          name="creditingPeriodDuration"
          label="Duration"
          rules={[{ required: true, message: 'Please input the duration!' }]}
        >
          <RangePicker picker='date'/>
        </Form.Item>
        <Form.Item label="Narration" name="creditingPeriodNarration" className='flex-1'>
          <Input placeholder="Please input the narration for this time period" />
        </Form.Item>
      </div>

      <Form.Item
        label={
          <span>
            Verified Credits&nbsp;
            <Tooltip 
              title="If we are updating a rating, please enter the number of newly verified credits rather than the cumulative total in order to avoid double counting (assuming we already entered the number of newly verified credits the last time the rating was updated)"
              placement='right'
              overlayInnerStyle={{ width: '700px' }}
            >
              <InfoCircleOutlined style={{ cursor: 'pointer' }} />
            </Tooltip>
          </span>
        }
        name="verifiedCredits"
      >
        <Input type='number' placeholder="Please input the number of verified credits" />
      </Form.Item>

      <Title level={5} style={{marginTop: '10px', marginBottom: '20px'}}>Registry-assigned Vintage Year(s)</Title>
      <Form.Item
        name="vintageYears"
      >
        {vintageYearsList.map((item, index) => (
          <div
            key={index}
            style={{
              marginBottom: '16px',
              padding: '20px',
              border: '1px solid #d9d9d9',
              borderRadius: '4px',
              backgroundColor: '#f7f7f7',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              alignItems: 'center',
              gap: '20px'
            }}
          > 
            <div style={{ display: 'flex', flexBasis: '30%', alignItems: 'center' }}>
              <Text strong style={{ marginRight: '8px' }}>Vintage Year:</Text>
              <Form.Item
                name={['vintageYears', index, 'year']}
                style={{ marginBottom: 0, flex: 1 }}
                rules={[{ required: true, message: 'Please select a vintage year!' }]}
              >
                <DatePicker picker='year' onChange={(value) => handleVintageYearChange(index, 'year', value)}/>
              </Form.Item>
            </div>

            {item.year && (
              <div style={{ display: 'flex', flexBasis: '40%', alignItems: 'center' }}>
                <Text strong style={{ marginRight: '8px' }}>Duration:</Text>
                <Form.Item
                  name={['vintageYears', index, 'duration']}
                  style={{ marginBottom: 0, flex: 1 }}
                  rules={[{ required: true, message: 'Please select a duration!' }]}
                >
                  <RangePicker
                    type='date'
                    disabledDate={(current) =>
                      current && current.year() !== dayjs(item.year).year()
                    }
                    onChange={(value) => handleVintageYearChange(index, 'duration', value)}
                  />
                </Form.Item>
              </div>
            )}

            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              onClick={() => removeVintageYear(index)}
            >
              Remove
            </Button>
          </div>
      ))}
       <Button type="dashed" icon={<PlusOutlined />} onClick={addVintageYear} style={{ marginBottom: '16px' }}>
        Add Registry Vintage Year
      </Button>
      </Form.Item>
    </>
  );
};

export default CreditingPeriodForm;

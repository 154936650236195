import React, { useState, useMemo, useCallback, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { uniq } from 'lodash';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise'; // Optional - enterprise only if you're using row grouping or aggregation

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { Button } from 'antd';
import { CalyxAGGrid } from '../CalyxAGGrid';
import { _ } from 'ag-grid-community';
import { isProjectLive } from '../../utils';

const STYLE_MAP = {
    'cs_overcrediting_fnrb': {
        width: '405px'
    },
    're_additionality_country_context': {
        width: '405px'
    },
    're_overcrediting_baseline': {
        width: '550px'
    },
    're_additionality_common_practice': {
        width: '600px'
    }
};

const getXAxis = (chartConfig, rowData, chartType) => {
    if (chartType === 'redd_overcrediting_baseline_3' || chartType === 'redd_overcrediting_baseline_2') {
        return {
            ...chartConfig?.xAxis,
            data: rowData.map(d => d.type)
        };
    }

    return chartConfig?.xAxis;
};

const getSeriesData = (chartConfig, rowData, chartType, country = 'Brazil', state) => {
    if (chartType === 'redd_overcrediting_baseline_1') {
        const projectAppliedReferencePeriod = rowData.filter(r => r.project_applied_reference_period).sort(x => x.year);
        const projectAppliedReferenceStartingYear = projectAppliedReferencePeriod[0]?.year;
        const projectAppliedReferenceEndingYear = projectAppliedReferencePeriod.at(-1)?.year;
        const projectAppliedReferenceStartingIndex = rowData.findIndex(r => r.year === projectAppliedReferenceStartingYear);
        const projectAppliedReferenceEndingIndex = rowData.findIndex(r => r.year === projectAppliedReferenceEndingYear);


        const calyxReferencePeriod = rowData.filter(r => r.calyx_reference_period).sort(x => x.year);
        const calyxReferenceStartingYear = calyxReferencePeriod[0]?.year;
        const calyxReferenceEndingYear = calyxReferencePeriod.at(-1)?.year;
        const calyxReferenceStartingIndex = rowData.findIndex(r => r.year === calyxReferenceStartingYear);
        const calyxReferenceEndingIndex = rowData.findIndex(r => r.year === calyxReferenceEndingYear);

        let markAreaSeries = [] as any;
        if (projectAppliedReferenceStartingIndex !== -1 && projectAppliedReferenceEndingIndex !== -1) {
            markAreaSeries.push({
                name: 'Project Applied Reference Period',
                type: 'bar',
                data: [],
                markArea: {
                    data: [[{
                        name: '',
                        xAxis: projectAppliedReferenceStartingIndex,
                        itemStyle: {
                            color: "rgba(197, 162, 203, 0.4)"
                        },
                    }, {
                        xAxis: projectAppliedReferenceEndingIndex,
                    }]]
                }
            });
        }
        if (calyxReferenceStartingIndex !== -1 && calyxReferenceEndingIndex !== -1) {
            markAreaSeries.push({
                name: 'Calyx Reference Period',
                type: 'bar',
                data: [],
                markArea: {
                    data: [[{
                        name: '',
                        xAxis: calyxReferenceStartingIndex,
                        itemStyle: {
                            color: "rgba(113, 176, 115, 0.4)"
                        },
                    }, {
                        xAxis: calyxReferenceEndingIndex,
                    }]]
                }
            });
        }

        let markLineSeries = [] as any;
        const projectStartDate = rowData.find(r => r.project_start)?.year;
        const projectStartDateIndex = rowData.findIndex(r => r.year === projectStartDate);
        if (projectStartDateIndex !== -1) {
            markLineSeries.push({
                name: '',
                type: 'bar',
                data: [],
                markLine: {
                    data: [{
                        name: '',
                        xAxis: projectStartDateIndex,
                        label: {
                            formatter: '{b}',
                            position: 'middle'
                        },
                        itemStyle: {
                            color: 'rgba(255, 255, 255, 1)'
                        },
                        symbol: 'none'
                    }]
                }
            });
        }
        return chartConfig.series.map((series, index) => {
            if (index === 2 && rowData.map(r => r.project_defined_reference_region).filter(f => f).length === 0) {
                return null;
            }
            return series;
        }).filter(s => s).concat(markAreaSeries).concat(markLineSeries);
    } else if (chartType === 'redd_overcrediting_baseline_2') {
        return chartConfig.series.map((series, index) => {
            const projectBaseline = Number(rowData.find(r => r.type === 'Project Baseline')?.forest_loss);
            const projectObserved = Number(rowData.find(r => r.type === 'Project Observed')?.forest_loss);
            const calyxModel = Number(rowData.find(r => r.type === 'Calyx Model')?.forest_loss);
            const calyxObserved = Number(rowData.find(r => r.type === 'Calyx Observed')?.forest_loss);

            const projectBaselinePercentage = ~~((projectBaseline - calyxModel) / calyxModel * 100);

            let markline1Text;
            if (projectBaseline - projectObserved > 0) {
                markline1Text = `Project Estimated reduction is ${projectBaseline - projectObserved} ha/year`;
            } else if (projectBaseline - projectObserved < 0) {
                markline1Text = `Project Estimated increase is ${projectObserved - projectBaseline} ha/year`;
            }

            let markline2Text;
            if (projectBaselinePercentage > 0) {
                markline2Text = `Project baseline is ${projectBaselinePercentage}% higher`;
            } else if (projectBaselinePercentage < 0) {
                markline2Text = `Project baseline is ${projectBaselinePercentage}% lower`;
            }

            let markline3Text;
            if (calyxModel - calyxObserved > 0) {
                markline3Text = `Calyx Estimated reduction is ${calyxModel - calyxObserved} ha/year`;
            } else if (calyxModel - calyxObserved < 0) {
                markline3Text = `Calyx Estimated increase is ${calyxObserved - calyxModel} ha/year`;
            }

            const markLine1 = [{
                xAxis: 0,
                yAxis: projectBaseline,
                lineStyle: {
                    color: '#000'
                },
                label: {
                    formatter: markline1Text,
                    textStyle: {
                        color: '#324148',
                        fontFamily: '"Open Sans", sans-serif',
                        fontSize: 10, fontWeight: 700
                    },
                }
            }, {
                xAxis: 1,
                yAxis: projectObserved
            }];
            const markLine2 = [{
                xAxis: 2,
                yAxis: calyxModel,
                lineStyle: {
                    color: '#00ff00'
                },
                label: {
                    formatter: markline2Text,
                    textStyle: {
                        color: '#324148',
                        fontFamily: '"Open Sans", sans-serif',
                        fontSize: 10,
                        fontWeight: 700
                    }
                }
            }, {
                xAxis: 0,
                yAxis: projectBaseline
            }];
            const markLine3 = [{
                xAxis: 2,
                yAxis: calyxModel,
                label: {
                    formatter: markline3Text,
                    textStyle: {
                        color: '#324148',
                        fontFamily: '"Open Sans", sans-serif',
                        fontSize: 10,
                        fontWeight: 700
                    }
                }
            }, {
                xAxis: 3,
                yAxis: calyxObserved
            }];

            const colors = ['#2A3A8E', '#8F2913', '#4661EC', '#EE4520'];

            return {
                type: 'bar',
                data: rowData.map((row, index) => {
                    return {
                        value: row.forest_loss,
                        itemStyle: {
                            color: colors[index]
                        }
                    };
                })
            };
        });
    } else if (chartType === 'redd_overcrediting_baseline_3') {
        return chartConfig.series.map((series, index) => {
            const colors = ['#2A3A8E', '#384DBD', '#4661EC', '#37528E'];
            return {
                type: 'bar',
                data: rowData.map((row, index) => {
                    return {
                        value: Number(row?.forest_loss?.replace?.('%', '')),
                        itemStyle: {
                            color: colors[index]
                        }
                    };
                })
            };
        });
    } else if (chartType === 'redd_overcrediting_baseline_4') {
        return chartConfig.series.map((series, index) => {
            const colors = ['#2A3A8E', '#EE4520', '#FFCB14', '#8F2913'];

            if (index === 1 && rowData.map(r => r.project_observed_degradation).filter(f => f).length === 0) {
                return null;
            }

            if (rowData.map(r => r[series.dimensions[1]]).filter(r => r).length === 0) {
                return null;
            }

            return {
                ...series,
                itemStyle: {
                    color: colors[index]
                }
            };
        }).filter(f => f);
    } else if (chartType === 'redd_permanence_3') {
        return chartConfig.series.map((series, index) => {
            return {
                type: 'scatter',
                data: rowData.map(row => {
                    if (row.country === country) {
                        return {
                            name: row.country,
                            value: [
                                Number(row.government_effectiveness),
                                Number(row.strength_civil_society),
                            ],
                            itemStyle: {
                                color: '#ffff00'
                            }
                        };
                    }

                    return {
                        name: row.country,
                        value: [
                            Number(row.government_effectiveness),
                            Number(row.strength_civil_society),
                        ]
                    };
                })
            };
        });
    } else if (chartType === 'car_mexico_chart_8') {
        return chartConfig.series.map((series, index) => {
            return {
                type: 'scatter',
                data: rowData.map(row => {
                    if (row.state === state) {
                        return {
                            name: row.state,
                            value: [
                                Number(row.government_average),
                                Number(row.civil_average),
                            ],
                            itemStyle: {
                                color: '#ffff00'
                            }
                        };
                    }

                    return {
                        name: row.state,
                        value: [
                            Number(row.government_average),
                            Number(row.civil_average),
                        ]
                    };
                })
            };
        });
    } else if (chartType === 're_additionality_common_practice_2') {
        return uniq(rowData.map(row => row.location)).map(location => {
            return {
                ...chartConfig.series[0],
                name: location,
                data: rowData.filter(row => row.location === location).map(row => {
                    return [
                        Number(row.year),
                        Math.round(Number(row.penetration_rate) * 100) / 100,
                    ];
                })
            };
        });
    } else if (chartType === 'ar_chart_2' || chartType === 're_overcrediting_baseline_1') {
        return chartConfig.series.map((series, index) => {

            if (rowData.map(r => r[series.dimensions[1]]).filter(f => f).length === 0) {
                return null;
            }

            return series;
        }).filter(s => s);
    } else if (chartType === 'ar_chart_1') {
        let markAreaSeries = [] as any;
        const projectStartDate = rowData.find(r => r.project_start)?.year;
        const projectStartDateIndex = rowData.findIndex(r => r.year === projectStartDate);
        if (projectStartDateIndex !== -1) {
            markAreaSeries.push({
                name: '',
                type: 'bar',
                data: [],
                markArea: {
                    data: [[{
                        name: '',
                        xAxis: 0,
                        itemStyle: {
                            color: "rgba(113, 176, 115, 0.4)"
                        },
                    }, {
                        xAxis: projectStartDateIndex
                    }]]
                }
            });
        }

        return chartConfig.series.concat(markAreaSeries);
    } else if (chartType === 'ar_chart_5') {
        const currentProjectIndex = rowData.findIndex(r => r.current_project == '1');
        return [{
            ...chartConfig.series[0],
            data: rowData.map((row, index) => {
                if (index === currentProjectIndex) {
                    return {
                        value: [
                            row.years,
                            row.projects_count
                        ],
                        itemStyle: {
                            color: '#FFCB14'
                        }
                    };
                }
                return [
                    row.years,
                    row.projects_count
                ];
            })
        }];
    } else if (chartType === 'redd_permanence_1') {
        const currentProjectIndex = rowData.findIndex((r) => r.current_project == '1');
        return [
          {
            ...chartConfig.series[0],
            data: rowData.map((row, index) => {
              const yAxisValue = row.proportion_of_redd_projects?.includes('%')
                ? row.proportion_of_redd_projects?.split('%')?.[0]
                : row.proportion_of_redd_projects;
              if (index === currentProjectIndex) {
                return {
                  value: [row.period, yAxisValue],
                  itemStyle: {
                    color: '#FFCB14',
                  },
                };
              }
              return [row.period, yAxisValue];
            }),
          },
        ];
      }
     else if (chartType === 'manure_management_chart_2') {
        const currentProjectIndex = rowData.findIndex(r => r.is_project_value === '1');
        const thirdMonthIndex = rowData.findIndex(r => r.retention_time == '3');

        const markPointData = [] as Array<{
            coord: [any, number],
            itemStyle: {
                color: string
            }
        }>;
        if (thirdMonthIndex > -1) {
            markPointData.push({
                coord: [rowData[thirdMonthIndex]?.retention_time, Number(rowData[thirdMonthIndex]?.mcf?.replace('%', '').replace(',', ''))],
                itemStyle: {
                    color: '#22C11F'
                }
            });
        }
        if (currentProjectIndex > -1) {
            markPointData.push({
                coord: [rowData[currentProjectIndex]?.retention_time, Number(rowData[currentProjectIndex]?.mcf?.replace('%', '').replace(',', ''))],
                itemStyle: {
                    color: '#EE4520'
                }
            });
        }

        return [{
            ...chartConfig.series[0],
            data: rowData.map((row, index) => {
                if (index === currentProjectIndex) {
                    return null;
                }
                return [
                    row.retention_time,
                    Number(row.mcf?.replace('%', '').replace(',', ''))
                ];
            }).filter(f => f),
            markPoint: {
                symbol: 'circle',
                symbolSize: 10,
                data: markPointData
            }
        }];
    }else if (chartType === 'ar_chart_3') {
        return [
          {
            ...chartConfig.series[0],
            itemStyle: {
              normal: {
                color: function (params) {
                  if (params.data.type === 'Project') {
                    return '#FFCB13';
                  }
                  return '#4992ff';
                },
              },
            },
          },
        ];
      }

    return chartConfig?.series;
}

const getTableData = (rowData, chartType) => {
    if (chartType === 'cs_overcrediting_fnrb') {
        return {
            source: rowData.map(row => ({
                ...row,
                fnrb: Number(row?.fnrb?.replace?.('%', ''))
            }))
        };
    } else if (chartType === 're_additionality_country_context') {
        return {
            source: rowData.map(row => ({
                ...row,
                non_retired_carbon_credits: Number(row?.non_retired_carbon_credits?.replace?.('%', ''))
            }))
        };
    } else if (chartType === 're_overcrediting_baseline') {
        return {
            source: rowData.map(row => ({
                ...row,
                actual_gef: Number(row?.actual_gef?.replace?.('%', '')),
                project_gef: Number(row?.project_gef?.replace?.('%', ''))
            }))
        };
    } else if (chartType?.startsWith('re_additionality_common_practice_1')) {
        return {
            source: rowData.map(row => ({
                ...row,
                year: Number(row?.year),
                energy_other_sources: Number(row?.energy_other_sources),
                specific_tech_installed_capacity: Number(row?.specific_tech_installed_capacity),
                penetration_rate: Number(row?.penetration_rate)
            }))
        };
    } else if (chartType?.startsWith('re_additionality_common_practice_2')) {
        return {
            source: rowData.map(row => ({
                ...row,
                year: Number(row?.year),
                penetration_rate: Number(row?.penetration_rate)
            }))
        };
    } else if (chartType?.startsWith('re_additionality_common_practice_3')) {
        return {
            source: rowData.map(row => ({
                ...row,
                // year: Number(row?.year),
                energy_other_sources: Number(row?.energy_other_sources),
                specific_tech_installed_capacity: Number(row?.specific_tech_installed_capacity)
            }))
        };
    } else if (chartType === 'redd_overcrediting_baseline_1') {
        return {
            source: rowData.map(row => ({
                ...row,
                year: Number(row?.year),
                project_area: Number(row?.project_area?.replace?.('%', '')),
                buffer_area: Number(row?.buffer_area?.replace?.('%', '')),
                project_defined_reference_region: Number(row?.project_defined_reference_region?.replace?.('%', '').replace?.(',', '')),
            }))
        };
    } else if (chartType === 'redd_overcrediting_baseline_2') {
        return {
            source: rowData.map(row => ({
                ...row,
            }))
        };
    } else if (chartType === 'redd_overcrediting_baseline_3') {
        return {
            source: rowData.map(row => ({
                ...row,
                forest_loss: Number(row?.forest_loss?.replace?.('%', ''))
            }))
        };
    }
    // else if (chartType === 'redd_overcrediting_baseline_4') {
    //     return {
    //         source: rowData.map(row => ({
    //             ...row,
    //         }))
    //     };
    // } 
    else if (chartType === 'redd_permanence_1') {
        return {
            source: rowData.map(row => ({
                ...row,
                proportion_of_redd_projects: Number(row?.proportion_of_redd_projects?.replace?.('%', ''))
            }))
        };
    }
    // else if (chartType === 'redd_permanence_2') {
    //     return {
    //         source: rowData.map(row => ({
    //             ...row,
    //         }))
    //     };
    else if (chartType === 'redd_permanence_3') {
        return {
            source: rowData.map(row => ({
                ...row,
                government_effectiveness: Number(row?.government_effectiveness?.replace?.('%', '')),
                strength_civil_society: Number(row?.strength_civil_society?.replace?.('%', '')),
            }))
        };
    } else if (chartType === 'ar_chart_1') {
        return {
            source: rowData.map(row => ({
                ...row,
                planted_forest: Number(row?.planted_forest?.replace?.(',', ''))
            }))
        };
    } else if (chartType === 'ar_chart_2') {
        return {
            source: rowData.map(row => ({
                ...row,
                project_cumulative_removal: Number(row?.project_cumulative_removal?.replace?.(',', '')),
                winrock_teak_removals: Number(row?.winrock_teak_removals?.replace?.(',', '')),
                winrock_other_confier_removals: Number(row?.winrock_other_confier_removals?.replace?.(',', '')),
                winrock_eucalyptus_removals: Number(row?.winrock_eucalyptus_removals?.replace?.(',', '')),
            }))
        };
    } else if (chartType === 'ar_chart_4') {
        return {
            source: rowData.map(row => ({
                ...row,
                achieved_net_ghg_er: Number(row?.achieved_net_ghg_er?.replace?.(',', '')),
                estimated_net_ghg_er: Number(row?.estimated_net_ghg_er?.replace?.(',', ''))
            }))
        };
    } else if (chartType === 'car_mexico_chart_8') {
        return {
            source: rowData.map(row => ({
                ...row,
                government_average: Number(row?.government_average?.replace?.(',', '')).toFixed(2),
                civil_average: Number(row?.civil_average?.replace?.(',', '')).toFixed(2)
            }))
        };
    } else if (chartType === 'dry_forest_chart_1') {
        return {
            source: rowData.map(row => ({
                ...row,
                project_defined_area: Number(row?.project_defined_area?.replace?.('%', '')),
                project_area: Number(row?.project_area?.replace?.('%', '')),
            }))
        };
    } else if (chartType === 'manure_management_chart_1') {
        return {
            source: rowData.map(row => ({
                ...row,
                delta_irr: Number(row?.delta_irr?.replace?.('%', ''))
            }))
        };
    } else if (chartType === 'manure_management_chart_2') {
        return {
            source: rowData.map(row => ({
                ...row,
                mcf: Number(row?.mcf?.replace?.('%', ''))
            }))
        };
    }

    return {
        source: rowData.map(row => ({
            ...row,
        }))
    };
}


type ChartEditorProps = {
    saveData: (data: any) => void;
    project: any;
    item: any;
    section: any;
    entityId: string;
    queryKey: string;
    id: any;
};

const ChartCapture: React.FC<ChartEditorProps> = ({
    saveData,
    project,
    section,
    entityId,
    queryKey,
    item,
    id
}) => {
    const chartConfig = item?.rating_chart_config?.config?.chart_config;
    const tableConfig = item?.rating_chart_config?.config?.table_config;
    const tableData = item?.rating_chart_data?.data || [];


    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(() => (tableConfig?.defaultColDef), [tableConfig?.defaultColDef]);
    const rowData = useMemo(() => (Object.assign(tableConfig?.rowData || [], tableData)), [JSON.stringify(tableData)]);
    const columnDefs = useMemo(() => (tableConfig?.columnDefs || []), [tableConfig?.columnDefs]);
    const echartsOptions = useMemo(() => {
        return Object.assign({}, chartConfig || {}, {
            xAxis: getXAxis(chartConfig, rowData, item?.rating_chart_config?.chart_type),
            dataset: getTableData(rowData, item?.rating_chart_config?.chart_type),
            series: getSeriesData(chartConfig, rowData, item?.rating_chart_config?.chart_type, project.country, project.state)
        })
    }, [chartConfig, rowData, item?.rating_chart_config?.chart_type,project]);

    const gridStyle = STYLE_MAP[item?.rating_chart_config?.chart_type] || {};

    console.log('echarts', item?.rating_chart_config?.chart_type, echartsOptions);

    return (
        <div className='flex flex-col'>
            {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
            <CalyxAGGrid
                disabled={isProjectLive(project)}
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                rowData={rowData} // Row Data
                saveData={saveData}
                gridStyle={gridStyle}
            />
            <div className='flex-1'>
                <ReactECharts
                    option={echartsOptions}
                />
            </div>
        </div>
    );
};

export default ChartCapture;
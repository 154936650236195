import { useEffect, useState } from 'react';
import { sortedIndexBy } from 'lodash';
import { Select, message } from 'antd';
import { createProponent } from '../../../api/proponent';
import './loading.css';

const { Option } = Select;

const normalizeString = (str) => {
  return str?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')?.toLowerCase();
};

const sleep = async ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const SelectProponent = ({ initialItems, form }) => {
  const [items, setItems] = useState<any>([]);
  const [newItem, setNewItem] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasNoMatches, setHasNoMatches] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>([]);

  useEffect(() => {
    if (initialItems && initialItems.length > 0) {
      setItems(initialItems);
      setSelectedItems(form.getFieldValue('proponent'));
    }
  }, [initialItems]);

  useEffect(() => {
    const filteredItems = items.filter(item => normalizeString(item.display_name).includes(normalizeString(newItem)));
    const noMatches = filteredItems.length === 0 && newItem.trim() !== '';
    setHasNoMatches(noMatches);

  }, [items, newItem]);


  const handleAddItem = async () => {
    const data = newItem.trim();
    if (data !== '') {
      try {
        setLoading(true);
        const response = await createProponent({ name: data });
        const newItemData = response.response;
        const insertIndex = sortedIndexBy(items, newItemData, 'name');
        const updatedItems = [
          ...items.slice(0, insertIndex),
          newItemData,
          ...items.slice(insertIndex)
        ];
        await sleep(1000);
        setItems(updatedItems);
        setLoading(false);
        setNewItem('');
        setSelectedItems([...selectedItems, newItemData.id]);
        form.setFieldsValue({
          proponent: [...selectedItems, newItemData.id],
        });
        message.success({
          content: 'Added proponent',
          style: { textAlign: 'right' },
        });
      } catch (error: any) {
        setLoading(false);
        console.error('Error adding new item:', error);
        message.error({
          content: error.message,
          style: { textAlign: 'right' },
        });
      }
    }
  };

  const handleChange = async values => {
    if (values.includes('__CREATE__')) {
      await handleAddItem();
    } else {
      setSelectedItems(values);
      form.setFieldsValue({
        proponent: values,
      });
    }
  };


  const handleSearch = value => {
    setNewItem(value);
  };

  const dropdownRender = menu => {
    return (
      <div>
        {menu}
      </div>
    );
  };


  return (
    <Select
      mode="multiple"
      placeholder={`Select or add Proponent`}
      onSearch={handleSearch}
      onChange={handleChange}
      value={selectedItems}
      dropdownRender={dropdownRender}
      showSearch
      filterOption={(inputValue, option) => {
        const normalizedInput = normalizeString(inputValue);
        const normalizedOption = normalizeString(option?.children);
        return normalizedOption?.includes(normalizedInput);
      }}
      suffixIcon={
        hasNoMatches && loading ? (
          <div className="loading">...</div>
        ) : null
      }>
      {items.map(item => (
        <Option key={item.id} value={item.id}>
          {item.display_name}
        </Option>
      ))}
      {hasNoMatches && !loading && (
        <Option key="__CREATE__" value="__CREATE__">
          {`Create "${newItem}"`}
        </Option>
      )}
    </Select>
  );
};

export default SelectProponent;

import { AxiosResponse } from 'axios';
import axiosInstance from './axios';

export const getFieldMeta = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/esr/meta-data`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error fetching the field meta data: ${errorMessage}`);
  }
};

export const getProjectRisks = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/esr/project-risk?crediting_period_id=${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error fetching the project risks: ${errorMessage}`);
  }
};

export const updateSafeGuardArea = async (id: string, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`/esr/safeguard-area/${id}`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error updating the esr safeguard area: ${errorMessage}`);
  }
};

export const createESRSection = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`/esr/section`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error creating the esr section: ${errorMessage}`);
  }
};

export const createESR = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`/esr/template/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error creating the esr: ${errorMessage}`);
  }
};

export const deleteESR = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`/esr/template/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error deleting the esr: ${errorMessage}`);
  }
};

export const updateESRSection = async (id: string, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`/esr/section/${id}`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error updating the esr section: ${errorMessage}`);
  }
};

export const deleteESRSection = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`/esr/section/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error deleting the esr section: ${errorMessage}`);
  }
};

export const uploadESRImage = async (id: string, file: File): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axiosInstance.post(`/esr/section/image/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error uploading the ESR image: ${errorMessage}`);
  }
};

export const deleteESRImage = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`/esr/section/image/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error deleting the esr image: ${errorMessage}`);
  }
};

export const uploadProjectRiskImage = async (id: string, file: File): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axiosInstance.post(`/esr/project-risk/image/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error uploading the project risk image: ${errorMessage}`);
  }
};

export const deleteProjectRiskImage = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`/esr/project-risk/image/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error deleting the project risk image: ${errorMessage}`);
  }
};


export const createProjectRisk = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`esr/project-risk`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error creating the esr project risk: ${errorMessage}`);
  }
};

export const updateProjectRisk = async (id: string, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`esr/project-risk/${id}`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error updating the esr project risk: ${errorMessage}`);
  }
};

export const deleteProjectRisk = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`esr/project-risk/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error deleting the esr project risk: ${errorMessage}`);
  }
};

export const createProjectRiskSection = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`esr/project-risk-section`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error creating the esr project risk section: ${errorMessage}`);
  }
};

export const updateProjectRiskSection = async (id: string, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`esr/project-risk-section/${id}`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error updating the esr project risk section: ${errorMessage}`);
  }
};

export const createScreeningUpdate = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`esr/screening-updates`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error creating the esr screening update: ${errorMessage}`);
  }
};

export const updateScreeningUpdate = async (id: string, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`esr/screening-updates/${id}`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error updating the esr screening update: ${errorMessage}`);
  }
};

export const deleteScreeningUpdate = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.delete(`esr/screening-updates/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error deleting the esr screening update: ${errorMessage}`);
  }
};
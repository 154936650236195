import { Button, Card, InputNumber, Popconfirm, Select } from 'antd';
import {
    QuestionCircleOutlined
} from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { sortBy } from 'lodash';
import { DeleteFilled } from '@ant-design/icons';
import { putRatingTableData, postRatingTableData, deleteRatingTableData } from '../../api';
import ContentEditor from '../ContentEditor';
import TimelineDatePicker from './datepicker';
import { isProjectLive } from '../../utils';

type TimelineTableEditorProps = {
    saveData: (data: any) => void;
    project: any;
    item: any;
    section: any;
    entityId: string;
    queryKey: string;
    id: any;
};

const TimelineTableEditor: React.FC<TimelineTableEditorProps> = ({ saveData, project, section, entityId, queryKey, item, id }) => {
    const [rows, setRows] = React.useState<any>([]);
    const [columns, setColumns] = React.useState<any>([]);
    const client = useQueryClient();
    const {
        mutate: updateRatingTableData,
        isLoading,
        error
    } = useMutation(putRatingTableData, {
        onSuccess: (data) => {
            client.invalidateQueries({
                queryKey: [queryKey]
            });
        }
    });
    const {
        mutate: addRatingTableData,
        isLoading: isAdding,
        error: addError
    } = useMutation(postRatingTableData, {
        onSuccess: (data) => {
            client.invalidateQueries({
                queryKey: [queryKey]
            });
        }
    });
    const {
        mutate: removeRatingTableData,
        isLoading: isDeleting,
        error: deleteError
    } = useMutation(deleteRatingTableData, {
        onSuccess: (data) => {
            client.invalidateQueries({
                queryKey: [queryKey]
            });
        }
    });

    useEffect(() => {
        const { rating_table_attributes } = item;
        let tempColumns = [] as any;
        let tempRows = [] as any;
        rating_table_attributes.forEach((attr) => {
            tempColumns.push({
                rating_table_attribute_id: attr.id,
                key: attr.name,
                label: attr.value
            });
            sortBy(attr.rating_table_data, 'row_id').forEach((rowData, rowIndex) => {
                tempRows?.[rowIndex]
                    ? (tempRows[rowIndex][attr.name] = {
                        id: rowData.id,
                        row_id: rowData.row_id,
                        value: rowData.value,
                        ckeditor_content_doc: rowData.ckeditor_content_doc
                    })
                    : (tempRows[rowIndex] = {
                        [attr.name]: {
                            id: rowData.id,
                            row_id: rowData.row_id,
                            value: rowData.value,
                            ckeditor_content_doc: rowData.ckeditor_content_doc
                        }
                    });
            });
        });
        setColumns(tempColumns);
        setRows(tempRows);
    }, [item]);

    const onStatusChange = (r, key) => (value) => {
        const { id } = r[key];
        updateRatingTableData({
            ratingTableDataId: id,
            data: {
                value: `${value}`
            }
        });
    };

    const onAddTimelineitem = () => {
        addRatingTableData(columns.map(c => ({
            rating_table_attribute_id: c.rating_table_attribute_id,
            value: '',
            row_id: rows.length + 1
        })));
    };

    const onRemoveTimelineitem = (r) => () => {
        Object.keys(r).forEach((key) => {
            const { id } = r[key];
            removeRatingTableData(id);
        });
    };

    return (
        <div className='mt-1'>
            <div className='flex flex-col gap-8'>
                {rows.map((r, ri) => {
                    return (
                        <div className='flex items-center gap-2'>
                            {!isProjectLive(project) && <Popconfirm
                                title="Remove timeline item"
                                description="Are you sure to remove this timeline item?"
                                icon={(
                                    <QuestionCircleOutlined style={{ color: 'red' }} />
                                )}
                                onConfirm={onRemoveTimelineitem(r)}
                                okText="Yes, Delete the item"
                                cancelText="No, do not delete"
                            >
                                <Button type="link" danger icon={<DeleteFilled />}>
                                    Remove
                                </Button>
                            </Popconfirm>}
                            {columns.map((c, ci) => {
                                if (c.key === 'type') {
                                    return null;
                                } else if (c.key === 'timeline_date') {
                                    return (
                                        <TimelineDatePicker
                                            key={ci}
                                            projectLive={isProjectLive(project)}
                                            defaultValue={r[c.key].value}
                                            onChange={onStatusChange(r, c.key)}
                                        />
                                    );
                                } else if (c.key === 'description') {
                                    return (
                                        <ContentEditor
                                            project={project}
                                            entityId={entityId}
                                            queryKey={queryKey}
                                            saveData={saveData}
                                            sectionType={'table'}
                                            key={ci}
                                            tableDataId={r[c.key].id}
                                            id={`${id}_${r[c.key].id}`}
                                            data={r[c.key].ckeditor_content_doc}
                                        />
                                    );
                                }

                                return null;
                            })}
                        </div>
                    );
                })}
            </div>
            <Button
                type="dashed"
                className='mt-4'
                disabled={isProjectLive(project)}
                onClick={onAddTimelineitem}
            >
                Add timeline item
            </Button>
        </div>
    );
};

export default TimelineTableEditor;

import React, { useCallback, useEffect, useState } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '../../lib/ckeditor/src/ckeditor-cs.js';
import { useIsMounted } from 'usehooks-ts';
import { Spin } from 'antd';
import { CALYX_READONLY } from '../../utils';

import './custom.css';

const editorConfiguration = {
    removePlugins: [
        'FontFamily',
        'Title',
        'Heading',
        'PresenceList',
        'RealTimeCollaborativeComments',
        'RealTimeCollaborativeEditing',
        'RealTimeCollaborativeTrackChanges',
        'Comments',
        'TrackChanges',
        'ImportWord',
        'ImportWordEditing',
        'CloudServices'
    ],
    toolbar: {
        items: [
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'link',
            'subscript',
            'superscript',
            '|',
            'numberedList',
            'bulletedList',
            'outdent',
            'indent',
            'alignment',
            'specialCharacters'
        ]
    },
    initialData: '',
    placeholder: '',
    licenseKey: 'ZGRrMkwzZDQycHRpVXQxL0t4cmFNRVp5Ym9jY3A2ejlPY0tweTVobzRDdkV6UXVoUk4vWnVmczdkMDA4dWpzPS1NakF5TkRBMk1UST0=',
};

type CalyxCKEditorClassicProps = {
    data?: string;
    onDataChange: (data: string) => void;
    readOnly: boolean;
};

const CalyxCKEditorClassic: React.FC<CalyxCKEditorClassicProps> = ({
    data,
    onDataChange,
    readOnly
}) => {
    const [flag, setFlag] = useState(false);
    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted()) {
            setFlag(true);
        }
    }, [isMounted]);
    const [editorLoader, setEditorLoader] = useState(true);
    const onEditorReady = (editor) => {
        setEditorLoader(false);
        if (readOnly) {
            const toolbarElement = editor.ui.view.toolbar.element;
            toolbarElement.style.display = 'none';
            editor.enableReadOnlyMode(CALYX_READONLY);
        }
    };

    const onEditorError = (error) => {
        console.error('Editor error: ', error);
        setEditorLoader(false);
    };

    return (
        <>
            {editorLoader ? <Spin /> : null}
            <div className="flex flex-col flex-1">
                {flag && <CKEditor
                    //@ts-ignore
                    editor={Editor}
                    //@ts-ignore
                    config={editorConfiguration}
                    data={data}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        onDataChange(data);
                    }}
                    onReady={onEditorReady}
                    onError={onEditorError}
                />}
            </div>
        </>
    );
};

export default CalyxCKEditorClassic;

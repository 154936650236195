import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router';
import useAuth from '../hooks/useAuth';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  ProjectOutlined,
  EditOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import { Link } from 'react-router-dom';
import { useLogoutFunction } from '@propelauth/react';

const { Header, Sider, Content } = Layout;

const AppLayout: React.FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const getDefaultSelectedKey = () => {
    const pathSegments = pathname.split('/');
    const currentKey = pathSegments[1];
    const validKeys = ['projects', 'shared-content', 'locations', 'project_types', 'ghg_standards', 'proponent'];
    return validKeys.includes(currentKey) ? [currentKey] : ['projects'];
  };

  const onMenuItemClick = ({ key }) => {
    navigate(key);
  };

  const logoutFunction = useLogoutFunction();
  const onLogout = async () => {
    await logoutFunction(false);
  };

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Link to='/'>
          <div className='text-white text-xl font-semibold logo m-2 ml-4 my-5 whitespace-nowrap overflow-clip'>
            Calyx Global
          </div>
        </Link>
        <Menu
          theme="dark"
          mode="inline"
          onClick={onMenuItemClick}
          defaultSelectedKeys={getDefaultSelectedKey()}
          items={[
            {
              key: 'projects',
              icon: <ProjectOutlined />,
              label: 'Projects'
            },
            {
              key: 'shared-content',
              icon: <EditOutlined />,
              label: 'Shared Content'
            },
            {
              key: 'locations',
              icon: <EditOutlined />,
              label: 'Locations'
            },
            {
              key: 'project_types',
              icon: <EditOutlined />,
              label: 'Project Types'
            },
            {
              key: 'ghg_standards',
              icon: <EditOutlined />,
              label: 'Registry and Methodology'
            },
            {
              key: 'proponents',
              icon: <EditOutlined />,
              label: 'Proponents'
            }
          ]}
        />
        <div className='absolute bottom-0 w-full'>
          <Button
            type='default'
            onClick={onLogout}
            className='w-full'
            danger
          >
            <LogoutOutlined />
            Logout
          </Button>
        </div>
      </Sider>
      <Layout className='min-h-screen' style={{ marginLeft: 200 }}>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default AppLayout;

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { updateProjectTypeGroup, getProjectTypeGroupById } from '../../../api/project_type';
import './edit.css';

const EditProjectGroupComponent: React.FC = () => {
  const { id } = useParams<{ id }>();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchProjectGroup() {
      setLoading(true);
      try {
        const groupData = await getProjectTypeGroupById(id);
        setLoading(false);
        form.setFieldsValue(groupData.response);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching project group:', error);
      }
    }

    fetchProjectGroup();
  }, [id, form]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await updateProjectTypeGroup(id, { ...values, name: values.display_name });
      message.success({
        content: 'Project group updated successfully',
        duration: 4,
        style: { textAlign: 'right' },
      });
      navigate('/project_groups');
    } catch (error: any) {
      console.error('Error updating project group:', error);
      message.error({
        content: error.message,
        duration: 4,
        style: { textAlign: 'right' },
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className="update-project-group-container">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <div className="form-container">
        <h1>Update Project Group</h1>
        <Form
          form={form}
          name="updateProjectGroupForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Name"
            name="display_name"
            rules={[{ required: true, message: 'Please input the name of the project group!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EditProjectGroupComponent;

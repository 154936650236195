import React from 'react';

export function convertGHGRatingFromNumbertoText(number) {
  switch (number) {
    case 1:
      return 'A+';
    case 2:
      return 'A';
    case 3:
      return 'B+';
    case 4:
      return 'B';
    case 5:
      return 'C+';
    case 6:
      return 'C';
    case 7:
      return 'D+';
    case 8:
      return 'D';
    case 9:
      return 'E+';
    case 10:
      return 'E';
    default:
      return '-';
  }
}

export function convertSDGRatingFromNumbertoText(number) {
  switch (number) {
    case 1:
      return '+1';
    case 2:
      return '+2';
    case 3:
      return '+3';
    case 4:
      return '+4';
    case 5:
      return '+5';
    default:
      return '-';
  }
}

export const CALYX_READONLY = Symbol('calyxReadOnly');

export function isProjectLive(project) {
  return !!(project?.project_crediting_periods?.[0]?.project_workflow?.state === 'production');
}

export const getSDGRatingColor = (sdgRating: string) => {
  return (
    {
      '+5': '#1FA21C',
      '+4': '#35AB33',
      '+3': '#4CB549',
      '+2': '#62BE60',
      '+1': '#79C777'
    }?.[sdgRating] ?? ''
  );
};

export const getGHGratingColor = (ghgRating: string) => {
  return (
    {
      'A+': '#1FA21C',
      A: '#35AB33',
      'B+': '#86BD09',
      B: '#92C422',
      'C+': '#EBC606',
      C: '#EDCC1F',
      'D+': '#FF8310',
      D: '#FF8F28',
      'E+': '#EF5928',
      E: '#F16A3D',
      'N/A': '#000000'
    }?.[ghgRating] ?? ''
  );
};

export const getRiskColor = (risk: number) => {
  if (risk === 0) {
    return '#808080';
  }
  // return ['#EE4520', '#FF8F0C', '#FFDB1D', '#9EDA18', '#22C11F']?.[5 - risk] ?? '';
  return ['red', 'volcano', 'orange', 'lime', 'green']?.[5 - risk] ?? '';
};

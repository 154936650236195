import { useEditor, EditorContent, Editor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Link from '@tiptap/extension-link';
import Bold from '@tiptap/extension-bold';
import Underline from '@tiptap/extension-underline';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Code from '@tiptap/extension-code';
import History from '@tiptap/extension-history';
import OrderedList from '@tiptap/extension-ordered-list';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import Blockquote from '@tiptap/extension-blockquote';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import Heading from '@tiptap/extension-heading';

import Toolbar from '../toolbar/toolbar';
import './texteditor.css';
import { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

interface SimpleEditorProps {
  onChange: (content: string) => void;
  initialData: string;
  editable: boolean;
}

const SimpleEditor: React.FC<SimpleEditorProps> = ({ initialData, onChange, editable }) => {
  const debouncedOnChange = useCallback(
    debounce((html) => {
      onChange(html);
    }, 1000),
    []
  );
  const editor = useEditor({
    extensions: [
      Document,
      History,
      Paragraph,
      Text,
      Link.configure({
        openOnClick: false
      }),
      Bold,
      Underline,
      Italic,
      Strike,
      Code,
      Superscript,
      Subscript,
      OrderedList,
      BulletList,
      ListItem,
      Blockquote,
      TextAlign.configure({
        types: ['heading', 'paragraph']
      }),
      Heading.configure({
        levels: [1, 2, 3]
      })
    ],
    editable,
    content: initialData,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      debouncedOnChange(html);
    },
  }) as Editor;

  useEffect(() => {
    if (editor?.isEmpty) {
      editor.commands.setContent(initialData, false, {preserveWhitespace: "full"});
    }
  }, [initialData, editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="editor">
      <Toolbar editor={editor} disable={!editable}/>
      <EditorContent editor={editor}/>
    </div>
  );
};

export default SimpleEditor;

import React, { useState } from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { createGHGStandard } from '../../../api/ghg';
import './create.css';

const CreateGHGStandardComponent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      await createGHGStandard(values);
      message.success({
        content: 'GHG standard created successfully',
        duration: 4,
        style: { textAlign: 'right' },
      });
      navigate('/ghg_standards');
    } catch (error: any) {
      console.error('Error creating GHG standard:', error);
      message.error({
        content: error.message,
        duration: 4,
        style: { textAlign: 'right' },
      });
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className="create-ghg-standard-container">
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <div className="form-container">
        <h1>Create GHG Standard</h1>
        <Form
          name="createGHGStandardForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true, message: 'Please input the code of the GHG standard!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Full Form"
            name="full_form"
            rules={[{ required: true, message: 'Please input the full form of the GHG standard!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: false }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateGHGStandardComponent;

import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBold, 
  faItalic, 
  faUnderline, 
  faStrikethrough, 
  faLink, 
  faQuoteLeft, 
  faSubscript, 
  faSuperscript, 
  faListOl, 
  faListUl, 
  faAlignLeft, 
  faAlignCenter, 
  faAlignRight, 
  faUndo, 
  faRedo 
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip, Select } from 'antd';

interface ToolbarProps {
  editor: any;
  disable?: boolean;
}

const Toolbar: React.FC<ToolbarProps> = ({ editor, disable = false }) => {
  const toggleBold = () => editor?.chain().focus().toggleBold().run();
  const toggleItalic = () => editor?.chain().focus().toggleItalic().run();
  const toggleUnderline = () => editor?.chain().focus().toggleUnderline().run();
  const toggleStrike = () => editor?.chain().focus().toggleStrike().run();
  const toggleBlockquote = () => editor?.chain().focus().toggleBlockquote().run();
  const toggleSubscript = () => editor?.chain().focus().toggleSubscript().run();
  const toggleSuperscript = () => editor?.chain().focus().toggleSuperscript().run();
  const toggleOrderedList = () => editor?.chain().focus().toggleOrderedList().run();
  const toggleBulletList = () => editor?.chain().focus().toggleBulletList().run();
  const setTextAlign = (align: 'left' | 'center' | 'right') => editor?.chain().focus().setTextAlign(align).run();
  const toggleLink = () => {
    const url = window.prompt("URL");
    if (url) {
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    }
  };

  const undo = () => editor?.chain().focus().undo().run();
  const redo = () => editor?.chain().focus().redo().run();

  const setHeading = (level: number) => {
    if (level >= 1 && level <= 3) {
      editor.chain().focus().toggleHeading({ level }).run();
    } else {
      editor.chain().focus().setParagraph().run();
    }
  };

  return (
    <div className="menu">
      <Tooltip title="Undo" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button onClick={undo} className="menu-button" disabled={disable}>
          <FontAwesomeIcon icon={faUndo} />
        </button>
      </Tooltip>
      <Tooltip title="Redo" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button onClick={redo} className="menu-button" disabled={disable}>
          <FontAwesomeIcon icon={faRedo} />
        </button>
      </Tooltip>

      <Select 
        onChange={(value) => setHeading(Number(value))} 
        className="menu-dropdown"
        defaultValue="0"
        style={{
          width: 120,
        }}
        disabled={disable}
      >
        <Select.Option value="0">Paragraph</Select.Option>
        <Select.Option value="1">Heading 1</Select.Option>
        <Select.Option value="2">Heading 2</Select.Option>
        <Select.Option value="3">Heading 3</Select.Option>
      </Select>

      <div className="separator" style={{ height: '24px', width: '1px', backgroundColor: '#ccc', margin: '0 10px' }}></div>

      <Tooltip title="Bold" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("bold") })} 
          onClick={toggleBold}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faBold} />
        </button>
      </Tooltip>
      <Tooltip title="Italic" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("italic") })} 
          onClick={toggleItalic}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faItalic} />
        </button>
      </Tooltip>
      <Tooltip title="Underline" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("underline") })} 
          onClick={toggleUnderline}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faUnderline} />
        </button>
      </Tooltip>
      <Tooltip title="Strikethrough" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("strike") })} 
          onClick={toggleStrike}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faStrikethrough} />
        </button>
      </Tooltip>
      <Tooltip title="Link" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("link") })} 
          onClick={toggleLink}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faLink} />
        </button>
      </Tooltip>
      <Tooltip title="Blockquote" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("blockquote") })} 
          onClick={toggleBlockquote}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faQuoteLeft} />
        </button>
      </Tooltip>
      <Tooltip title="Subscript" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("subscript") })} 
          onClick={toggleSubscript}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faSubscript} />
        </button>
      </Tooltip>
      <Tooltip title="Superscript" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("superscript") })} 
          onClick={toggleSuperscript}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faSuperscript} />
        </button>
      </Tooltip>

      <div className="separator" style={{ height: '24px', width: '1px', backgroundColor: '#ccc', margin: '0 10px' }}></div>

      <Tooltip title="Ordered List" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("orderedList") })} 
          onClick={toggleOrderedList}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faListOl} />
        </button>
      </Tooltip>
      <Tooltip title="Bullet List" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("bulletList") })} 
          onClick={toggleBulletList}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faListUl} />
        </button>
      </Tooltip>
      <Tooltip title="Align Left" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("leftAlign") })} 
          onClick={() => setTextAlign('left')}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faAlignLeft} />
        </button>
      </Tooltip>
      <Tooltip title="Align Center" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("centerAlign") })} 
          onClick={() => setTextAlign('center')}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faAlignCenter} />
        </button>
      </Tooltip>
      <Tooltip title="Align Right" placement="bottom" overlayStyle={{ fontSize: '12px' }}>
        <button 
          className={classNames("menu-button", { "is-active": editor?.isActive("rightAlign") })} 
          onClick={() => setTextAlign('right')}
          disabled={disable}
        >
          <FontAwesomeIcon icon={faAlignRight} />
        </button>
      </Tooltip>
    </div>
  );
};

export default Toolbar;

import { AgGridReact } from "ag-grid-react";
import { Button } from "antd";
import React, { createRef } from "react";

type CalyxAGGridType = {
    columnDefs: any,
    defaultColDef: any,
    rowData: any,
    saveData: any,
    gridStyle: any,
    disabled: boolean
};

export class CalyxAGGrid extends React.Component<CalyxAGGridType> {

    private gridRef = createRef<any>();

    private onGridReady = () => { };
    private saveGridData = () => {
        const currentRowData = [] as any;
        this.gridRef?.current.api.getModel().forEachNode(node => {
            currentRowData.push(node.data);
        })
        this.props.saveData({
            rating_chart_data: {
                update: {
                    data: currentRowData
                }
            }
        });
    };
    private onCellValueChanged = () => {
        this.saveGridData();
    };
    private onModelUpdated = (event) => {
        this.saveGridData()
    };

    private onAddRow = () => {
        this.gridRef?.current.api.applyTransaction({
            add: [{}]
        });
    };

    private getContextMenuItems = (params) => {
        const result = [{
            name: 'Delete Row',
            action: () => {
                this.gridRef.current.api.applyTransaction({ remove: [params.node.data] });
            },
            cssClasses: ['text-red-500']
        }];
        return result;
    }

    shouldComponentUpdate(): boolean {
        return false;
    }

    render(): React.ReactNode {
        const { columnDefs, defaultColDef, rowData, gridStyle, disabled } = this.props;

        return (
            <div className='flex flex-col flex-1'>
                <Button disabled={disabled} className='my-2 w-40' onClick={this.onAddRow}>Add row</Button>
                <div className="ag-theme-alpine" style={{
                    width: '80%'
                }}>
                    <AgGridReact
                        ref={this.gridRef} // Set ref for Grid
                        domLayout='autoHeight'
                        onGridReady={this.onGridReady}
                        rowData={rowData}
                        suppressClickEdit={disabled}
                        suppressClipboardPaste={disabled}
                        suppressContextMenu={disabled}
                        columnDefs={columnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        rowSelection='multiple' // Options - allows click selection of rows
                        enableRangeSelection={true} // Optional - allows click and drag selection of cells
                        onCellValueChanged={this.onCellValueChanged}
                        onModelUpdated={this.onModelUpdated}
                        getContextMenuItems={this.getContextMenuItems}
                        processDataFromClipboard={params => {
                            const emptyLastRow =
                                params.data[params.data.length - 1][0] === '' &&
                                params.data[params.data.length - 1].length === 1;

                            if (emptyLastRow) {
                                params.data.splice(params.data.length - 1, 1);
                            }

                            const gridOptions = this.gridRef.current;
                            const lastIndex = gridOptions.api.getModel().rowsToDisplay.length - 1;
                            const focusedCell = gridOptions.api.getFocusedCell();
                            const focusedIndex = focusedCell.rowIndex;

                            if (focusedIndex + params.data.length - 1 > lastIndex) {
                                const resultLastIndex = focusedIndex + (params.data.length - 1);
                                const addRowCount = resultLastIndex - lastIndex;
                                let rowsToAdd = [] as any;
                                let addedRows = 0;
                                let currIndex = params.data.length - 1;
                                while (addedRows < addRowCount) {
                                    rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
                                    addedRows++;
                                    currIndex--;
                                }
                                rowsToAdd = rowsToAdd.reverse();
                                let newRowData = [] as any;
                                rowsToAdd.map((r) => {
                                    let row = {};
                                    let currColumn = focusedCell.column;
                                    r.map((i) => {
                                        row[currColumn.colDef.field] = i;
                                        currColumn = gridOptions.columnApi.getDisplayedColAfter(currColumn);
                                    });
                                    newRowData.push(row);
                                });
                                gridOptions.api.applyTransaction({ add: newRowData });
                            }

                            return params.data;
                        }}
                    />
                </div>
            </div>
        );
    }
}
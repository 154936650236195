import { useEffect, useState } from 'react';
import { useAuthInfo } from "@propelauth/react";
import { setAccessToken } from '../api/axios';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const PropelAuthProvider = ({ children }) => {
  const authInfo = useAuthInfo();
  const [isTokenSet, setIsTokenSet] = useState(false);

  useEffect(() => {
    if (authInfo && authInfo.accessToken) {
      setAccessToken(authInfo.accessToken);
      setIsTokenSet(true);
    }
  }, [authInfo]);

  if (!isTokenSet) {
    return (
      <Spin
        spinning={!isTokenSet}
        indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      />
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export default PropelAuthProvider;

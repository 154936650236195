import React, { useCallback, useEffect, useState } from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import axiosInstance, { BASE_URL } from '../../api/axios';
import Editor from '../../lib/ckeditor/src/ckeditor-cs.js';
import { useIsMounted } from 'usehooks-ts';
import { Spin } from 'antd';

const editorConfiguration = ({
  entityId,
  id,
  wordCountRef,
  sidebarElementRef,
  presenceElementRef,
  data,
  isShared
}) => {
  return {
    removePlugins: ['FontFamily', 'Title'],
    collaboration: {
      channelId: `${process.env.NODE_ENV}_ckeditor_${entityId}_${id}`
    },
    wordCount: {
      container: wordCountRef.current,
      displayCharacters: true,
      displayWords: true
    },
    toolbar: {
      items: [
        'undo',
        'redo',
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'blockQuote',
        'subscript',
        'superscript',
        '|',
        'numberedList',
        'bulletedList',
        'outdent',
        'indent',
        'alignment',
        '|',
        'comment',
        'trackChanges',
        'selectAll',
        'specialCharacters',
        // 'wproofreader',
        // 'exportPdf'
      ]
    },
    initialData: isShared ? data?.shared_content_paragraph?.content : data?.content,
    placeholder: '',
    cloudServices: {
      tokenUrl: () => {
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(`${BASE_URL}/auth/ckeditor-token`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => reject(error));
        });
      },
      uploadUrl: 'https://98266.cke-cs.com/easyimage/upload/',
      webSocketUrl: 'wss://98266.cke-cs.com/ws',
      bundleVersion: 'calyx_ckeditor_2.0.0'
    },
    licenseKey: 'ZGRrMkwzZDQycHRpVXQxL0t4cmFNRVp5Ym9jY3A2ejlPY0tweTVobzRDdkV6UXVoUk4vWnVmczdkMDA4dWpzPS1NakF5TkRBMk1UST0=',
    sidebar: {
      container: sidebarElementRef.current
    },
    presenceList: {
      container: presenceElementRef.current
    },
    revisionssidebar: {
      container: sidebarElementRef.current
    }
  };
};

type CalyxCKEditorProps = {
  onChange?: (event: any, editor: any) => void;
  saveData?: (data: string) => void;
  data?: any;
  isShared?: boolean;
  entityId: string;
  id: any;
};

const CalyxCKEditor: React.FC<CalyxCKEditorProps> = ({
  id,
  entityId,
  isShared,
  onChange,
  saveData,
  data
}) => {
  const [flag, setFlag] = useState(false);
  const isMounted = useIsMounted();
  useEffect(() => {
    if (isMounted()) {
      setFlag(true);
    }
  }, [isMounted]);
  const [editorLoader, setEditorLoader] = useState(true);
  const wordCountRef = React.useRef(null);
  const presenceElementRef = React.useRef<HTMLDivElement>(null);
  const sidebarElementRef = React.useRef<HTMLDivElement>(null);


  const onEditorReady = (editor) => {
    setEditorLoader(false);
    editor.focus?.();
    // if (data?.content) {
    //   editor.setData(data.content);
    // }
    editor.editing.view.change((writer) => {
      // @ts-ignore
      writer.setStyle('height', '400px', editor.editing.view.document.getRoot());
    });
  };

  const onEditorError = (error) => {
    console.error('Editor error: ', error);
    setEditorLoader(false);
  };

  return (
    <>
      <div className='presence' ref={presenceElementRef}></div>
      {editorLoader ? <Spin /> : null}
      <div className="flex">
        <div className="flex flex-col flex-1">
          {flag && <CKEditor
            //@ts-ignore
            editor={Editor}
            //@ts-ignore
            config={editorConfiguration({
              entityId,
              id,
              wordCountRef,
              sidebarElementRef,
              presenceElementRef,
              data,
              isShared
            })}
            onChange={onChange}
            onReady={onEditorReady}
            onError={onEditorError}
          />}
          <div className="" ref={wordCountRef}></div>
        </div>
        <div className='sidebar w-48 max-h-80 overflow-auto' ref={sidebarElementRef}></div>
      </div>
    </>
  );
};

export default CalyxCKEditor;

import Root from './routes/index';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';

const App = () => {
  return (
    <ConfigProvider
        theme={{
        token: {
          colorPrimary: '#1f7a1f',
          borderRadius: 2,
        },
        algorithm: []
      }}>
      <Root/>
    </ConfigProvider>
  );
};

export default App;

import { AxiosResponse } from 'axios';
import axiosInstance, { BASE_URL } from './axios';

export const getProponents = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('/proponents');
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching proponents: ${error}`);
  }
};

export const getProponentById = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/proponents/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching proponent by id: ${error}`);
  }
};

export const createProponent = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`/proponents`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error creating the proponent: ${errorMessage}`);
  }
};

export const updateProponent = async (id: number, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`/proponents/${id}`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error updating the proponent: ${errorMessage}`);
  }
};

import { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Timeline, Typography, message, DatePicker, Input, Select, Popconfirm, Spin } from 'antd';
import { DeleteOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { getRatingChanges, createRatingChange, updateRatingChange, deleteRatingChange } from '../../../api/project';
import { debounce } from 'lodash';
import { getGHGRatingOptions, getRatingReasonFieldNames } from '../../../config/rating.config';

const ghgRatingOptions = getGHGRatingOptions();
const { Option } = Select;

const TimelineModal = ({ id }) => {
  const [visible, setVisible] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible && id) {
      fetchRatingChanges();
    }
  }, [visible, id]);

  const fetchRatingChanges = async () => {
    setLoading(true);
    try {
      const response = await getRatingChanges(id);
      setItems(response.data);
    } catch (error) {
      message.error('Failed to fetch rating changes');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateRatingChange = async (id, updatedItem, previousItem) => {
    try {
      await updateRatingChange(id, updatedItem);
    } catch (error) {
      setItems(items.map(item => item.id === id ? previousItem : item));
      message.error('Failed to update rating change');
    }
  };

  const handleDeleteRatingChange = async (id) => {
    const prevItems = [...items];
    try {
      await deleteRatingChange(id);
      setItems(items.filter(item => item.id !== id));
    } catch (error) {
      setItems(prevItems);
      message.error('Failed to delete rating change');
    }
  };

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  const handleFieldChange = (id, field, value) => {
    const previousItem = items.find((item) => item.id === id);
    if (previousItem) {
      const newValue = field === 'change_date' ? (value ? dayjs(value).utc().format() : dayjs().utc().format()) : value;
      const updatedItem = { ...previousItem, [field]: newValue };
      setItems(items.map((item) => (item.id === id ? updatedItem : item)));
        if (field === 'reason') {
        debouncedUpdateRatingChange(id, { [getRatingReasonFieldNames(field)]: newValue }, previousItem);
      } else {
        handleUpdateRatingChange(id, { [getRatingReasonFieldNames(field)]: newValue }, previousItem);
      }
    }
  };

  const debouncedUpdateRatingChange = useCallback(
    debounce(async (id, updatedItem, previousItem) => {
      await handleUpdateRatingChange(id, updatedItem, previousItem);
    }, 1000),
    []
  );

  const addNewRatingChange = async () => {
    try {
      const newRatingChange = {
        creditingPeriodId: id,
        change_date: dayjs.utc(),
        type: 'rating_changed',
      };
      const response = await createRatingChange(newRatingChange);
      const createdItem = response.data;
      setItems([...items, createdItem]);
    } catch (error) {
      message.error('Failed to create new rating change');
    }
  };


  const initialItem = items[0];
  const changeItems = items.slice(1);

  const initialSection = initialItem && {
    children: (
      <div className="space-y-4">
        <Typography.Title level={4} style={{ marginBottom: '16px', color: '#1890ff' }}>Initial Rating</Typography.Title>
        <div style={{
          marginBottom: '24px',
          padding: '20px',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          backgroundColor: '#f7f7f7',
          boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        }}>
          <div className="flex items-center gap-4">
            <div className="flex items-center" style={{ flexBasis: '50%' }}>
              <Typography.Text strong style={{ color: '#555' }}>Original Rating Date:</Typography.Text>
              <DatePicker
                value={dayjs.utc(initialItem.change_date)}
                onChange={(date) => handleFieldChange(initialItem.id, 'change_date', date)}
                style={{ marginLeft: 8, flex: 1, borderRadius: '4px' }}
              />
            </div>

            <div className="flex items-center" style={{ flexBasis: '50%' }}>
              <Typography.Text strong style={{ color: '#555' }}>Rating:</Typography.Text>
              <Select
                value={initialItem.new_rating}
                onChange={(value) => handleFieldChange(initialItem.id, 'new_rating', value)}
                style={{ marginLeft: 8, flex: 1, borderRadius: '4px' }}
              >
                {ghgRatingOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
    ),
  };

  const changeSection = {
    children: (
      <>
        <Typography.Title level={4} style={{ marginBottom: '16px', color: '#1890ff' }}>
          Changes
        </Typography.Title>
        {changeItems.map((item) => (
          <div
            key={item.id}
            style={{
              marginBottom: '24px',
              padding: '20px',
              border: '1px solid #d9d9d9',
              borderRadius: '8px',
              backgroundColor: '#f7f7f7',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            }}
          >
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <div className="flex items-center" style={{ flexBasis: '50%' }}>
                  <Typography.Text strong style={{ color: '#555' }}>Date:</Typography.Text>
                  <DatePicker
                    value={dayjs.utc(item.change_date)}
                    onChange={(date) => handleFieldChange(item.id, 'change_date', date)}
                    style={{ marginLeft: 46, flex: 1, borderRadius: '4px' }}
                  />
                </div>
                <div className="flex items-center" style={{ flexBasis: '50%' }}>
                  <Typography.Text strong style={{ color: '#555' }}>Type:</Typography.Text>
                  <Select
                    value={item.type}
                    onChange={(value) => handleFieldChange(item.id, 'type', value)}
                    style={{ marginLeft: 52, flex: 1, borderRadius: '4px' }}
                  >
                    <Option value="rating_changed">Rating Changed</Option>
                    <Option value="rating_not_changed">Rating Reviewed, No Rating Change</Option>
                  </Select>
                </div>
              </div>

              {item.type === 'rating_changed' && (
                <div className="flex items-center gap-4">
                  <div className="flex items-center" style={{ flexBasis: '50%' }}>
                    <Typography.Text strong style={{ color: '#555' }}>Old Rating:</Typography.Text>
                    <Select
                      value={item.old_rating}
                      onChange={(value) => handleFieldChange(item.id, 'old_rating', value)}
                      style={{ marginLeft: 8, flex: 1, borderRadius: '4px' }}
                    >
                      {ghgRatingOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>

                  <div className="flex items-center" style={{ flexBasis: '50%' }}>
                    <Typography.Text strong style={{ color: '#555' }}>New Rating:</Typography.Text>
                    <Select
                      value={item.new_rating}
                      onChange={(value) => handleFieldChange(item.id, 'new_rating', value)}
                      style={{ marginLeft: 8, flex: 1, borderRadius: '4px' }}
                    >
                      {ghgRatingOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}

              {item.type === 'rating_changed' && (
                <div className="flex items-center" style={{ marginTop: '8px' }}>
                  <Typography.Text strong style={{ color: '#555' }}>Reason:</Typography.Text>
                  <Input.TextArea
                    value={item.reason}
                    onChange={(e) => handleFieldChange(item.id, 'reason', e.target.value)}
                    placeholder="Reason for rating change"
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    style={{ marginLeft: 28, flex: 1, borderRadius: '4px', maxWidth: '60%' }}
                  />
                </div>
              )}
            </div>

            <div style={{ marginTop: '16px', textAlign: 'right' }}>
              <Popconfirm
                title="Are you sure you want to remove this item?"
                onConfirm={() => handleDeleteRatingChange(item.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" icon={<DeleteOutlined />} style={{ color: 'red' }}>
                  Remove
                </Button>
              </Popconfirm>
            </div>
          </div>
        ))}
        <Button type="dashed" icon={<PlusOutlined />} onClick={addNewRatingChange} style={{ marginBottom: '16px' }}>
          Add New Change
        </Button>
      </>
    ),
  };

  return (
    <>
      <Button type='primary' onClick={openModal}>
        Review & Rating Timeline
      </Button>

      <Modal
        title={<Typography.Title level={4} style={{ paddingBottom: '26px', display: 'block', color: '#008080' }}>Rating Timeline</Typography.Title>}
        open={visible}
        onCancel={closeModal}
        footer={null}
        width={'70%'}
        confirmLoading={loading}
      >
        {!loading ? (
        initialItem ? (
          <Timeline items={[initialSection, changeSection]} />
        ) : (
          <div style={{
            textAlign: 'center',
            padding: '10px',
            fontSize: '18px'
          }}>
            Timeline is available only for projects that are already in production.
          </div>
        )) : (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Spin
              spinning={loading}
              indicator={<LoadingOutlined style={{ fontSize: 24, color: 'blue' }} spin />}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default TimelineModal;

import { AxiosResponse } from 'axios';
import axiosInstance, { BASE_URL } from './axios';

export const getProjectTypes = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('/project_types');
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching project types: ${error}`);
  }
};

export const getProjectTypeById = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/project_types/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching project type by id: ${error}`);
  }
};

export const createProjectType = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`/project_types`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error creating the project type: ${errorMessage}`);
  }
};

export const updateProjectType = async (id: number, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`/project_types/${id}`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error updating the project type: ${errorMessage}`);
  }
};

export const getProjectTypeGroups = async (): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get('/project_groups');
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching project type groups: ${error}`);
  }
};

export const getProjectTypeGroupById = async (id: string): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.get(`/project_groups/${id}`);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error fetching project type group by id: ${errorMessage}`);
  }
};

export const createProjectTypeGroup = async (data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.post(`/project_groups`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error creating the project type group: ${errorMessage}`);
  }
};

export const updateProjectTypeGroup = async (id: number, data: any): Promise<any> => {
  try {
    const response: AxiosResponse = await axiosInstance.patch(`/project_groups/${id}`, data);
    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'internal service error';
    throw new Error(`Error updating the project type group: ${errorMessage}`);
  }
};

import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios({
      method: 'post',
      url: 'auth/refresh'
    });
    const { user } = response.data;
    setAuth({ user });
  };
  return refresh;
};

export default useRefreshToken;

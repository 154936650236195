import React, { useState, useEffect } from 'react';
import { find, isString, isUndefined } from 'lodash';
import { Form, Input, Select, DatePicker, Spin, Button, Tooltip, message, Space, Tag, Badge, Radio, Switch } from 'antd';
import { LoadingOutlined, ReloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { getProjectById, updateProject, updateProjectRatingById, createExpertCalibrationProject, removeExpertCalibrationProject } from '../../../api/project';
import { getGHGRatingOptions, getRatingOptions } from '../../../config/rating.config';
import dayjs from 'dayjs';
import { getGHGratingColor, getRiskColor } from '../../../utils';
import utc from 'dayjs/plugin/utc';
import TimelineModal from './ratingchange';

dayjs.extend(utc);

interface GHGRatingFormProps {
  form: any;
  workflowState: any;
}

const GHGRatingForm: React.FC<GHGRatingFormProps> = ({ form, workflowState }) => {
  const { id, creditingPeriodId } = useParams<{ id, creditingPeriodId: string }>();
  // const [isOverRide, setIsOverRide] = useState<Boolean>(false);
  const [creditingPeriod, setCreditingPeriod] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const ratingOptions = getRatingOptions();
  const [open, setOpen] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const ghgRatingOptions = getGHGRatingOptions();
  const getDefaultOptions = getRatingOptions();

  useEffect(() => {
    async function fetchProject() {
      setLoading(true);
      try {
        const projectData = await getProjectById(id, Number(creditingPeriodId));
        setLoading(false);
        const creditingPeriod = projectData.response.project_crediting_periods.find(creditingPeriod => creditingPeriod.crediting_period_id === Number(creditingPeriodId));
        setCreditingPeriod(creditingPeriod);
        form.setFieldsValue({
          ghgRiskRatingDate: creditingPeriod.ratings.ghg_risk_rating_date ? dayjs.utc(creditingPeriod.ratings.ghg_risk_rating_date) : '',
          ghgRiskRatingNotes: creditingPeriod.ratings.ghg_risk_rating_notes,
          additionality: ratingOptions.find(option => option.value == creditingPeriod?.ratings.additionality),
          permanence: ratingOptions.find(option => option.value == creditingPeriod?.ratings.permanence),
          overlappingClaims: ratingOptions.find(option => option.value == creditingPeriod?.ratings.overlapping_claims),
          baseline: ratingOptions.find(option => option.value == creditingPeriod?.ratings.baseline),
          projectEmissions: ratingOptions.find(option => option.value == creditingPeriod?.ratings.project_emissions),
          leakage: ratingOptions.find(option => option.value == creditingPeriod?.ratings.leakage),
          manualKlugingScore: creditingPeriod.ratings.manual_kluging_score,
          overcreditingCategory: find(getDefaultOptions, { value: creditingPeriod.ratings.overcrediting_score })?.label,
          finalratingCategory: find(ghgRatingOptions, { value: creditingPeriod.ratings.ghg_risk_rating })?.label,
          isOverRide: projectData.response.is_override,
          expertCalibrationStatus: projectData.response.expert_calibration_status,
        });
      } catch (error) {
        setLoading(false);
        console.error('Error fetching project:', error);
      }
    }

    fetchProject();
  }, [id, form]);

  const refreshRatings = async () => {
    try {
      const formData = form.getFieldsValue();
      const data = {
        additionality: isString(formData.additionality) ? formData.additionality : formData.additionality?.value,
        permanence: isString(formData.permanence) ? formData.permanence : formData.permanence?.value,
        overlappingClaims: isString(formData.overlappingClaims) ? formData.overlappingClaims : formData.overlappingClaims?.value,
        baseline: isString(formData.baseline) ? formData.baseline : formData.baseline?.value,
        projectEmissions: isString(formData.projectEmissions) ? formData.projectEmissions : formData.projectEmissions?.value,
        leakage: isString(formData.leakage) ? formData.leakage : formData.leakage?.value,
        ...(!isUndefined(formData.manualKlugingScore) && {
          manualKlugingScore: formData.manualKlugingScore,
        }),
      };
      setLoading(true);
      const projectResponse = await updateProject(id, Number(creditingPeriodId), data);
      setLoading(false);
      const creditingPeriod = find(projectResponse.response.project_crediting_periods, { crediting_period_id: Number(creditingPeriodId) });
      form.setFieldsValue({
        overcreditingCategory: creditingPeriod?.ratings.over_crediting_category,
        finalratingCategory: creditingPeriod?.ratings.ghg_risk_rating_category.rating,
      });
      message.success({
        content: 'Ratings refreshed',
        duration: 2,
        style: { textAlign: 'center' },
      });
    } catch (err) {
      setLoading(false);
      console.log('Error in refresh ratings api', err);
      message.error({
        content: 'Unable to refresh ratings',
        duration: 4,
        style: { textAlign: 'right' },
      });
    }

  };

  const updateExpertCalibrationStatus = async checked => {
    try {
      setIsApiCallInProgress(true);
      setLoading(true);
      if (checked) {
        await createExpertCalibrationProject(id);
        form.setFieldValue('expertCalibrationStatus', checked);
      } else {
        await removeExpertCalibrationProject(id);
        form.setFieldValue('expertCalibrationStatus', checked);
      }
      setLoading(false);
      setIsApiCallInProgress(false);
    } catch (err) {
      setLoading(false);
      console.error('Error in update expert calibration api:', err);
      message.error({
        content: 'Unable to update the expert calibration status',
        duration: 2,
        style: { textAlign: 'right' },
      });
    }
  };

  return (
    <>
      <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} fullscreen />
      <div style={{ textAlign: 'right' }}>
        <TimelineModal id={creditingPeriod?.id} />
      </div>
      <Form.Item
        label="GHG Risk Rating Date"
        name="ghgRiskRatingDate"
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="GHG Notes (Internal use)"
        name="ghgRiskRatingNotes"
      >
        <Input.TextArea />
      </Form.Item>
      <div className='flex flex-wrap gap-x-4'>
        <Form.Item
          label="Additionality"
          name="additionality"
          className='w-52'
          rules={[{ required: false, message: 'Please select Additionality!' }]}
        >
          <Select
            options={ratingOptions.map(option => ({
              key: option.value.toString(),
              value: option.value.toString(),
              label: (
                <Badge color={getRiskColor(Number(option.value))} text={option.label} key={option.value} />
              ),
            }))}
            value={form.getFieldValue('additionality')}
          />
        </Form.Item>
        <Form.Item
          label="Permanence"
          name="permanence"
          className='w-52'
          rules={[{ required: false, message: 'Please input Permanence!' }]}
        >
          <Select
            options={ratingOptions.map(option => ({
              key: option.value.toString(),
              value: option.value.toString(),
              label: (
                <Badge color={getRiskColor(Number(option.value))} text={option.label} key={option.value} />
              ),
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Overlapping Claims"
          name="overlappingClaims"
          className='w-52'
          rules={[{ required: false, message: 'Please select Overlapping Claims!' }]}
        >
          <Select
            options={ratingOptions.map(option => ({
              key: option.value.toString(),
              value: option.value.toString(),
              label: (
                <Badge color={getRiskColor(Number(option.value))} text={option.label} key={option.value} />
              ),
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Baseline"
          name="baseline"
          className='w-52'
          rules={[{ required: false, message: 'Please select Baseline!' }]}
        >
          <Select
            options={ratingOptions.map(option => ({
              key: option.value.toString(),
              value: option.value.toString(),
              label: (
                <Badge color={getRiskColor(Number(option.value))} text={option.label} key={option.value} />
              ),
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Project Emissions / Removals"
          name="projectEmissions"
          className='w-52'
          rules={[{ required: false, message: 'Please input Project Emissions / Removals!' }]}
        >
          <Select
            options={ratingOptions.map(option => ({
              key: option.value.toString(),
              value: option.value.toString(),
              label: (
                <Badge color={getRiskColor(Number(option.value))} text={option.label} key={option.value} />
              ),
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Leakage"
          name="leakage"
          className='w-52'
          rules={[{ required: false, message: 'Please input Leakage!' }]}
        >
          <Select
            options={ratingOptions.map(option => ({
              key: option.value.toString(),
              value: option.value.toString(),
              label: (
                <Badge color={getRiskColor(Number(option.value))} text={option.label} key={option.value} />
              ),
            }))}
          />
        </Form.Item>
      </div>
      <Form.Item
        label="Expert Calibration"
        name="expertCalibrationStatus"
      >
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={(checked) => updateExpertCalibrationStatus(checked)}
          disabled={isApiCallInProgress || workflowState === 'production'}
        />
      </Form.Item>
      <Space className='flex flex-wrap items-end gap-x-4'>
        {form.getFieldValue('isOverRide') &&
          <Form.Item
            label={
              <div>
                Manual Kluging Score{' '}
                <Tooltip
                  title="Please enter a score between 0 to 21"
                  placement="right"
                  open={open}
                  trigger={['hover']}
                  onOpenChange={(openStatus) => setOpen(openStatus)}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            }
            name="manualKlugingScore"
            rules={[{ required: form.getFieldValue('expertCalibrationStatus') === true ? false : true, message: 'Please input Manual Kluging Score!' }]}
          >
            <Input />
          </Form.Item>
        }
        <Form.Item
          label="Over Crediting"
          name="overcreditingCategory"
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="GHG Rating"
          name="finalratingCategory"
        >
          {form.getFieldValue('expertCalibrationStatus') ? (
            <Select
              options={ghgRatingOptions.map(option => ({
                key: option.value.toString(),
                value: option.label.toString(),
                label: (
                  <Badge color={getGHGratingColor(option.label)} text={option.label} key={option.value} />
                ),
              }))}
            />
          ) : (
            <Input disabled={true} />
          )}
        </Form.Item>
        <Form.Item
          label=""
          name="refreshRatingButton"
        >
          <Button type="primary" onClick={refreshRatings} icon={<ReloadOutlined />} disabled={form.getFieldValue('expertCalibrationStatus') || workflowState === 'production'}>
            Refresh Rating
          </Button>
        </Form.Item>
      </Space>
      {form.getFieldValue('expertCalibrationStatus') ? (
        <span className='text-xs font-semibold'>Note: This is an expert calibrated project. Please select the ghg risk rating manually.</span>
      ) : (
        <span className='text-xs font-semibold'>Note: Please click the "Refresh Rating" button to view the updated GHG Rating if any rating values have changed.</span>
      )}
    </>
  );
};

export default GHGRatingForm;

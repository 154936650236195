import axios from 'axios';

// Log the build environment
console.log('build env', process.env.BUILD_ENV);

// Define the base URL based on the environment
export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_BUILD_ENV === 'prod'
      ? 'https://platform.calyxglobal.com'
      : 'https://platform.calyxstaging.com'
    : 'http://localhost:3000';

// Create an Axios instance with the base URL and default headers
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

// Function to set the access token
export const setAccessToken = (accessToken) => {
  axiosInstance.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
};

export default axiosInstance;